import classNames from 'classnames';
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';
import FetchError from '../../components/FetchError';
import QuestionButton from '../../components/questionButton';
import { scrollWindowToTop } from '../../scrolls';
import { useUkrnetSectionsQuery } from '../../services/messages';
import { RootState } from '../../store';
import { TopStickyDiv } from '../../styledComponents';
import { getDatasetAttr } from '../../utils';
import { changeUnSelectedRoute } from '../searcher/searcherSlice';
import UkrnetSectionMessages from './UkrnetSectionMessages';

interface IUkrnetLayoutProps extends PropsFromRedux {}
const UkrnetLayout = ({ selectedRoute, setSelectedRoute }: IUkrnetLayoutProps) => {
	const {
		data: sections,
		error,
		isSuccess,
	} = useUkrnetSectionsQuery(undefined, {
		pollingInterval: 15 * 60 * 1000,
	});

	useEffect(() => {
		if (!sections || sections.length === 0) return;
		// Заглушка на випадок, коли у списку секцій відсутня збережена користувачем секція
		const selectedInList = sections.some(({ route }) => route === selectedRoute);
		if (!selectedInList) setSelectedRoute(sections[0].route);
	}, [sections, setSelectedRoute]); // eslint-disable-line

	const onSectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const route = getDatasetAttr(event, '.btn-un-section', 'route');
		if (route) {
			setSelectedRoute(route);
			scrollWindowToTop('auto');
		}
	};

	if (isSuccess && sections.length === 0) return null;

	return (
		<Row className="mb-2">
			{!(sections?.length === 0)}
			<Col xs={12}>
				<div className="text-center bg-body-secondary p-2 position-relative">
					<div className="fs-4 text-condensed">
						Головні події<span className="d-none d-lg-inline"> України та світу</span>
					</div>
					<QuestionButton
						title="Список статей, вміст яких відповідає обраній новині, формується автоматично. У&nbsp;зв'язку з&nbsp;цим серед показаних статей можуть бути такі, що не&nbsp;зовсім відповідають оригінальній новині, але, за&nbsp;визначенням пошукового алгоритму, є на&nbsp;неї схожими."
						className="position-absolute end-0 center-y-with-transform me-2"
					/>
				</div>
			</Col>
			{/* <Col xs={12} lg={9} className="order-1 order-lg-0">
				{selectedRoute && <UkrnetSectionMessages section={selectedRoute} />}
			</Col> */}
			<Col xs={12} lg={3} className="mt-2">
				{error && <FetchError error={error} />}
				{/* {sectionsFetching && <InlineWaiter dark>Завантаження секцій</InlineWaiter>} */}
				<TopStickyDiv onClick={onSectionClick} className="d-flex flex-wrap flex-lg-column">
					{sections?.map(({ route, title }) => (
						<Button
							key={route}
							data-route={route}
							// size="sm"
							variant={route === selectedRoute ? 'secondary' : 'link'}
							className={classNames(
								'btn-un-section text-start text-nowrap text-condensed lh-sm py-0 px-1 rounded-0',
								route === selectedRoute ? 'position-sticky bottom-0 top-fit-adapted' : 'link-secondary'
							)}
						>
							{title}
						</Button>
					))}
				</TopStickyDiv>
			</Col>
			<Col xs={12} lg={9}>
				{selectedRoute && <UkrnetSectionMessages section={selectedRoute} />}
			</Col>
		</Row>
	);
};

const mapState = (state: RootState) => ({
	selectedRoute: state.searcher.unSelectedRoute,
});

const mapDispatch = {
	setSelectedRoute: changeUnSelectedRoute,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UkrnetLayout);
