import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { BsListColumns } from 'react-icons/bs';
import { PREDEFINED_SOURCES } from '../../interfaces/sources';
import WithSources, { IWithSources } from '../hoc/withSources';
import TextIcon from '../textIcon';

interface IDropdownPreSourcesProps extends IWithSources, React.AllHTMLAttributes<HTMLDivElement> {
	changeSources: ActionCreatorWithPayload<number[], string>;
	disabled?: boolean;
}
const DropdownPreSources = React.memo(({ changeSources, sourcesTop, disabled = false }: IDropdownPreSourcesProps) => {
	const [preSources, setPreSources] = useState(
		PREDEFINED_SOURCES.concat(sourcesTop.length !== 0 ? [{ label: 'Топ-30 джерел', sources: sourcesTop }] : [])
	);

	const onItemClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			const { index } = event.currentTarget.dataset;
			const listIndex = parseInt(index || '', 10);
			if (preSources[listIndex]) changeSources(preSources[listIndex].sources);
		},
		[changeSources, preSources]
	);

	useEffect(() => {
		setPreSources(
			PREDEFINED_SOURCES.concat(sourcesTop.length !== 0 ? [{ label: 'Топ-30 джерел', sources: sourcesTop }] : [])
		);
	}, [sourcesTop]);

	return (
		<Dropdown>
			<Dropdown.Toggle
				variant="outline-secondary"
				id="ddSelectedSources"
				title="Попередньо встановлені набори джерел"
				disabled={disabled}
				className="z-0"
			>
				<TextIcon Icon={BsListColumns} />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{preSources.map(({ label }, index) => (
					<Dropdown.Item as={Button} key={index} data-index={index} onClick={onItemClick}>
						{label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
});

export default WithSources(DropdownPreSources);
