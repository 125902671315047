import React from 'react';
import { Alert } from 'react-bootstrap';

interface IFetchErrorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	error: any;
}
const FetchError = ({ error, title = 'Помилка отримання даних' }: IFetchErrorProps) => (
	<Alert variant="danger">
		<div>
			<b>🤬 {title}:</b>
			{typeof error === 'object' && (
				<span className="ms-1">
					{error.originalStatus || 'No status'} &mdash; {error.data?.toString() || 'Невідома помилка'}
				</span>
			)}
		</div>
		<pre className="text-wrap">{JSON.stringify(error, undefined, '  ')}</pre>
	</Alert>
);

export default FetchError;
