import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

interface ILiveTimeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	timestamp: string; // В форматі ISO-8601
	showTime?: boolean;
}
const LiveTime = ({ timestamp, className, showTime = false }: ILiveTimeProps) => {
	// const [fromNow, setFromNow] = useState(dayjs(timestamp).fromNow());

	// useEffect(() => {
	// 	setFromNow(dayjs(timestamp).fromNow());
	// }, [timestamp]);

	// const updateFromNow = () => {
	// 	console.log(fromNow, dayjs(timestamp).fromNow());
	// 	setFromNow(dayjs(timestamp).fromNow());
	// };

	// useEffect(() => {
	// 	document.addEventListener(LIVE_TIME_EVENT_NAME, updateFromNow);
	// 	return () => document.removeEventListener(LIVE_TIME_EVENT_NAME, updateFromNow);
	// }, []);

	const dt = dayjs(timestamp);
	return (
		<span className={classNames(className, 'live-time')} data-time={timestamp} data-show-time={showTime ? 1 : 0}>
			{dt.fromNow()} {showTime && <>({dt.format(dt.isSame(dayjs(), 'date') ? 'LT' : 'L LT')})</>}
		</span>
	);
};

export default LiveTime;
