import classNames from 'classnames';
import React from 'react';

interface ICaptionProps extends React.AllHTMLAttributes<HTMLDivElement> {
	level?: number;
	imgSrc?: string;
	imgClassName?: string;
	imageFirst?: boolean;
}
const Caption = ({
	imageFirst = false,
	level = 5,
	title,
	className,
	imgSrc,
	imgClassName,
	children,
	...rest
}: ICaptionProps) => {
	return (
		<div className={classNames(`h${level}`, 'd-flex flex-column gap-3 align-items-center', className)} {...rest}>
			{title && <div className={classNames(imageFirst && 'order-1')}>{title}</div>}
			{children}
			{imgSrc && <img src={imgSrc} className={classNames(imgClassName, 'caption__image')} alt="No results" />}
		</div>
	);
};

export default Caption;

// import classNames from 'classnames';
// import React from 'react';

// interface ICaptionProps extends React.AllHTMLAttributes<HTMLDivElement> {
// 	level?: number;
// 	imgName?: string;
// 	imgClassName?: string;
// 	alt?: string;
// }
// const Caption = ({
// 	level = 5,
// 	title,
// 	className,
// 	imgName,
// 	imgClassName = 'w-25',
// 	children,
// 	alt = 'Ілюстрація',
// 	...rest
// }: ICaptionProps) => {
// 	return (
// 		<div className={classNames(`h${level}`, 'text-center', className)} {...rest}>
// 			{title}
// 			{children}
// 			{imgName && <img src={imgName} className={classNames('text-center mt-3', imgClassName)} alt={alt} />}
// 		</div>
// 	);
// };

// export default Caption;
