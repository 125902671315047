import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { QUERY_TAGS } from '../interfaces/common';
import { IDictionaries } from '../interfaces/dict';
import { RootState } from '../store';

export type TIssueType = 'b' | 'e' | 'h' | 'q';
export interface IIssue {
	title: string;
	body?: string;
	location: string;
	type: TIssueType;
}

export const searcherApi = createApi({
	reducerPath: 'searcherApi',
	baseQuery: fetchBaseQuery({
		baseUrl: 'api/',
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).app.accessToken;
			if (accessToken) {
				headers.set('Authorization', `Bearer ${accessToken}`);
			}
			return headers;
		},
	}),
	tagTypes: [QUERY_TAGS.rubric, QUERY_TAGS.source],
	refetchOnMountOrArgChange: 300,
	endpoints: (builder) => ({
		dictionaries: builder.query<IDictionaries, void>({
			query: () => `../public/api/dicts`,
			providesTags: (result) => {
				/**
				 * Тут потрібно було б вказати всі ідентифікатори джерел, але поки я не бачу в цьому сенсу,
				 * тому що інформація про всі джерела завантажується одним запитом в одному місті
				 * (при завантаженні словників), тому тут поки можна інвалідувати весь (принаймні поки що).
				 * Запити, які будуть будь-яким чином змінювати джерела, будуть теж інвалідувати весь список.
				 */
				return [{ type: QUERY_TAGS.source, id: 'list' }];
				// return result
				// 	? [
				// 			...result.sources.ids.map((id) => ({ type: QUERY_TAGS.source, id })),
				// 			{ type: QUERY_TAGS.source, id: 'list' },
				// 	  ]
				// 	: [{ type: QUERY_TAGS.source, id: 'list' }];
			},
		}),
		newIssue: builder.mutation<any, IIssue>({
			query: (body) => ({
				url: 'edit/issue',
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const { useDictionariesQuery, useNewIssueMutation } = searcherApi;
