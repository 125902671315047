import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Period from '../../components/params/period';
import { DEFAULTS, currentYMD } from '../../init';
import { RootState } from '../../store';
import { getObjectFromSearchParams } from '../../utils';
import { changeParams } from '../app/appSlice';
import { changeFacetRangeDateParams, getFacetRangeDate } from '../searcher/searcherSlice';

// Цей компонент відображається на сторінці рубрик лише коли активна статистика

interface IRubricPeriodProps extends PropsFromRedux {}
const RubricPeriod = ({
	params: { period_index, start_date, end_date },
	changeFacetRangeDateParams,
	changeParams,
}: IRubricPeriodProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [firstRender, setFirstRender] = useState(true);

	// Зміна налаштувань пошуку при зміні пошукових параметрів
	useEffect(() => {
		if (searchParams.get('date')) return;
		const { period_index, start_date, end_date } = getObjectFromSearchParams(
			searchParams,
			getDefaultPeriodSearchObject(null)
		);
		changeParams({ period_index, start_date, end_date });
		changeFacetRangeDateParams(getFacetRangeDate(parseInt(period_index), start_date, end_date));
		setFirstRender(false);
	}, [searchParams, changeParams, changeFacetRangeDateParams, setSearchParams]);

	// Зміна пошукових параметрів при зміні дати (в т. ч. при першому заході на сторінку)
	useEffect(() => {
		const initialDate = searchParams.get('date');
		if (!initialDate) return;

		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete('date');
		Object.entries(getDefaultPeriodSearchObject(initialDate)).forEach(([key, value]) =>
			newSearchParams.set(key, value)
		);
		setSearchParams(newSearchParams, { replace: true });
	}, [searchParams, setSearchParams]);

	// Зміна пошукових параметрів при зміні користувачем періоду дат
	useEffect(() => {
		if (firstRender) return;
		const newSearchParams = new URLSearchParams(searchParams);
		// newSearchParams.delete('date');
		newSearchParams.set('period_index', period_index);
		if (parseInt(period_index) === DEFAULTS.userCustomPeriodIndex) {
			newSearchParams.set('start_date', start_date || currentYMD());
			newSearchParams.set('end_date', end_date || currentYMD());
		} else {
			newSearchParams.delete('start_date');
			newSearchParams.delete('end_date');
		}
		if (newSearchParams.toString() !== searchParams.toString()) setSearchParams(newSearchParams, { replace: true });
	}, [period_index, start_date, end_date]); // eslint-disable-line

	return <Period />;
};

const mapState = (state: RootState) => ({
	params: state.app.searchParams,
});

const mapDispatch = {
	changeFacetRangeDateParams,
	changeParams,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RubricPeriod);

const getDefaultPeriodSearchObject = (hitDate: string | null) => ({
	period_index: DEFAULTS.userCustomPeriodIndex.toString(),
	start_date: hitDate || currentYMD(),
	end_date: hitDate || currentYMD(),
});
