import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import 'dayjs/locale/uk';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Storage } from './classes/storage';

dayjs.locale('uk');
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(localeData);

/**
 * The function `currentYMD` returns the current date in the format YYYYMMDD using the dayjs library.
 */
export const currentYMD = () => dayjs().format(YYYYMMDD);
export const TIME_ZONE_OFFSET = new Date().getTimezoneOffset();

/**
 * The function `getGMTForDate` calculates and returns the GMT offset for a given date or the current
 * date if no date is provided.
 * @param {string} [isoDate] - The `isoDate` parameter in the `getGMTForDate` function is an optional
 * parameter that represents a date in ISO 8601 format (e.g., "2022-01-01T12:00:00Z"). If provided, the
 * function will calculate the GMT offset based on
 * @returns The function `getGMTForDate` returns a string representing the GMT offset for the given
 * date in the format "GMT±HH:MM", where ± indicates the direction of the offset (either ahead or
 * behind GMT), HH represents the hours part of the offset, and MM represents the minutes part of the
 * offset.
 */
export const getGMTForDate = (isoDate?: string) => {
	const localOffsetInMinutes = (isoDate ? new Date(isoDate) : new Date()).getTimezoneOffset();
	const gmtOffsetHours = Math.floor(Math.abs(localOffsetInMinutes) / 60);
	const gmtOffsetMinutes = Math.abs(localOffsetInMinutes) % 60;
	const sign = -localOffsetInMinutes >= 0 ? '+' : '-';
	const hours = gmtOffsetHours.toString().padStart(2, '0');
	const minutes = gmtOffsetMinutes.toString().padStart(2, '0');
	return `GMT${sign}${hours}:${minutes}`;
};

export const USER_GMT = getGMTForDate();

/**
 * This TypeScript function calculates the offset in minutes from a given time zone string.
 * @param [TZ=Z] - The `TZ` parameter in the `getMinutesFromTZ` function is a string representing a
 * time zone offset in the format `GMT[+/-]HH:MM`. The function extracts the hours and minutes from
 * this string and calculates the total minutes offset from UTC. If the input string does not
 * @returns The function `getMinutesFromTZ` returns the number of minutes offset from GMT based on the
 * provided timezone string.
 */
export const getMinutesFromTZ = (TZ = 'Z') => {
	const m = TZ.match(/GMT([+-])0*(\d+):0*(\d+)/i);
	if (!m || m.length !== 4) return 0;
	const [dummy, sign, hours, minutes] = m; // eslint-disable-line
	return (sign === '+' ? 1 : -1) * (parseInt(hours, 10) * 60 + parseInt(minutes, 10));
};

// export const getMinutesFromTZ = (TZ: string = 'GMT+00:00') => {
// 	const =TZ.substring(3)
// 	const sign = TZ
// 	console.log(TZ);
// };

// const ISO_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

/**
 * This function takes a string date and returns it with a time of 00:00:00 appended to it.
 * @param {string} strDate - The parameter `date` is a string representing a date in format YYYY-MM-DD. It
 * is used as input to the function `getLocaleStrDate`.
 */
export const getLocaleStrDate = (strDate?: string | null) => (strDate || currentYMD()) + 'T00:00:00';

// export const getStrTimezoneOffset = (dt: Date) =>
// 	`${Math.round(dt.getTimezoneOffset() / 60).toLocaleString('uk-ua', { signDisplay: 'exceptZero' })}HOURS`;
// export const getStrTimeForPeriods = (dt: Date) => `${dayjs(dt).format(ISO_DATE_FORMAT)}${getStrTimezoneOffset(dt)}`;

export type TStringWithUndefined = string | undefined;
export type TNumberWithUndefined = number | undefined;

export interface IVideo {
	YOUTUBE_ID: string;
	PUBLISHED_AT: string;
	TITLE: string;
	LOGO: string;
	DURATION: number;
	channelLogo: string;
	channelTitle: string;
	channelYoutubeId: string;
	hl?: string[];
	real_duration?: number;
}

export interface IMessage {
	id: string;
	source_id: number;
	source_title: string;
	country: string;
	hit_date: string;
	title: string;
	score: number;
	// body?: [string, string];
	body?: string;
	body_len?: number;
	url?: string;
	content?: string;
}

export interface IUkrnetSectionItem {
	route: string;
	title: string;
}

export interface IUkrNetMessage {
	id: number;
	title: string;
	description: string;
	created: string;
	count: number;
}

export interface IDate {
	year: number;
	month: number;
	day: number;
}

export interface ICalendarData {
	date: string;
	count?: number;
}

export type THighlighting = { [key: string]: { body: string[] } };
export type TFacetValue = number | string;
export interface IFacetCounts {
	facet_fields: {
		country?: TFacetValue[];
		source_id?: TFacetValue[];
	};
	facet_ranges: {
		hit_date?: {
			counts: TFacetValue[];
		};
	};
}
export interface ISearchResponse {
	responseHeader: {
		partialResults?: boolean;
		QTime: number;
	};
	response?: {
		numFound: number;
		maxScore: number;
		docs: IMessage[];
	};
	error?: {
		metadata: string[];
		msg: string;
		code: number;
	};
	facet_counts: IFacetCounts;
	highlighting?: THighlighting;
}

/*
Такий об'єкт надходить з сервера
{
    "country_code": "UA",
    "country_code3": "UKR",
    "country_name": "Ukraine",
    "city_name": "Kyiv",
    "latitude": 50.458,
    "longitude": 30.5303,
    "time_zone": "Europe/Kyiv",
    "continent_code": "EU",
    "subdivision_code": "30",
    "subdivision_name": "Kyiv City"
}
*/
export interface IGeoIp {
	countryCode: string;
	countryCode3: string;
	countryName: string;
	cityName?: string;
	latitude: number;
	longitude: number;
	timeZone: string;
	continentCode: string;
	subdivisionCode: string;
	subdivisionName?: string;
}

export const NEED_ACCESS_TOKEN = 'Access token required';

export const DEFAULTS = {
	pageTitle: 'MAGNA',
	messagesPerPage: 20,
	srcImg: 'img/newspaper.webp',
	rubricImg: 'img/rubric.webp',
	userCustomPeriodIndex: 8,
	noMatch: 'збіги відсутні',
	availableScopes: 'read:news admin:news',
	accessPermissionScope: 'read:news',
	adminPermissionScope: 'admin:news',
	locale: 'uk-UA',
	hiddenOnSmallClass: 'd-none d-lg-inline',
	visibleOnSmallClass: 'd-inline d-lg-none',
	screenMdBorder: 768,
	entity: { ids: [], entities: {} },
	updateInterval: 60 * 15, // seconds
	// updateInterval: 10, // seconds for debug
	fromListState: { fromList: true },
};

export const YYYYMMDD = 'YYYY-MM-DD';

/**
 * The `promiseTimeOut` function creates a promise that resolves after a specified timeout period.
 * @param {number} timeout - The `timeout` parameter is a number representing the time duration in
 * milliseconds after which the promise will be resolved.
 */
export const promiseTimeOut = (timeout: number) => new Promise((res) => setTimeout(res, timeout));

// Фрази
export const EXIT_CONFIRMATION = 'Ви бажаєте вийти без збереження змін?';

// export const getTitleWithoutURL = (title: string) => title.replace(/\s+\([^)]+\)\s*$/, '');

// export const onExternalLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
// 	const href = (event.currentTarget as HTMLAnchorElement).getAttribute('href');
// 	if (!href) return;
// 	event.preventDefault();
// 	showAlert(href, 'Оригінальна адреса повідомлення');
// };

/**
 * The function `periodIsValid` checks if a given period is valid by comparing the start and end dates.
 * @param {TStringWithUndefined} startDate - The `startDate` parameter is a string representing the
 * start date of a period.
 * @param {TStringWithUndefined} endDate - The `endDate` parameter is a string representing a date in
 * the format YYYYMMDD.
 * @returns The function `periodIsValid` returns a boolean value.
 */
export const periodIsValid = (startDate: TStringWithUndefined, endDate: TStringWithUndefined) => {
	if (startDate === endDate) return true;
	if (!startDate || !endDate) return false;
	return dayjs(startDate, YYYYMMDD).isSameOrBefore(dayjs(endDate, YYYYMMDD), 'day');
};

/**
 * It checks if a permission exists in a list of permissions
 * @param {string} permissionForCheck - The permission you want to check for.
 * @param {string[]} permissions - The permissions that the user has.
 */
export const permissionExists = (permissionForCheck: string, permissions: string[] = []) =>
	permissions.includes(permissionForCheck);

/**
 * The `removeFakeTags` function removes fake tags (enclosed in ‹ and ›) from a given text string.
 * @param {string} text - The `removeFakeTags` function takes a string `text` as input and removes any
 * fake tags enclosed within `‹` and `›` from the text.
 */
export const removeFakeTags = (text: string) => text.replace(/‹[^›]+›/g, '');

// export const processFakeTags = (text: string) => text.replace(/‹/g, '<').replace(/›/g, '>');

/**
 * The `prepareSearchTerm` function in TypeScript prepares a search term by replacing certain
 * characters and patterns with escape sequences and logical operators.
 * @param {string} term - The `prepareSearchTerm` function takes a string input `term` and performs
 * several replacements on it to prepare it for search processing. Here's a breakdown of the
 * replacements it does:
 */
export const prepareSearchTerm = (term: string) => {
	const replaceFunc = (match: string, group1: string) =>
		`"${group1.replace(/\s+(and|і|и|та|or|или|або)\s+/g, ' __$1__ ')}"`;
	// const replaceFunc = (match: string, group1: string) => {
	// 	const replacedGroup1 = group1.replace(/\s+(and|і|и|та|or|или|або)\s+/g, ' __$1__ ');
	// 	return `"${replacedGroup1}"`;
	// };

	return term
		.replace(/:/g, '\\:')
		.replace(/"([^"]+)"/g, replaceFunc)
		.replace(/\s+(?:and|і|и|та)\s+/gi, ' && ')
		.replace(/\s+(?:or|или|або)\s+/gi, ' || ')
		.replace(/__(and|і|и|та|or|или|або)__/gi, '$1');
	// .replace(/("[^"]+)\s+(and|і|и|та|or|или|або)\s+([^"]+")/gi, '$1 __$2__ $3')
};

export const getTokenSilentlyAuthOptions = (
	scope = DEFAULTS.accessPermissionScope,
	withUserData = false
): GetTokenSilentlyOptions => {
	return {
		authorizationParams: {
			audience:
				process.env.NODE_ENV === 'production'
					? (process.env.REACT_APP_PROD_AUTH0_AUDIENCE as string)
					: (process.env.REACT_APP_DEV_AUTH0_AUDIENCE as string),
			scope: scope + (withUserData ? ' profile email offline_access' : ''),
		},
	};
};

/**
 * The function `compareStrings` compares two strings after removing non-alphanumeric characters and
 * special characters, and returns the result based on locale settings.
 * @param [str1] - The `compareStrings` function takes two strings as input, `str1` and `str2`, and
 * compares them after cleaning and normalizing the strings. The function removes any non-alphanumeric
 * characters and converts the strings to lowercase before comparing them using the `localeCompare`
 * method with a specified locale
 * @param [str2] - The `str2` parameter in the `compareStrings` function is the second string that you
 * want to compare with the first string (`str1`). The function will clean both strings by converting
 * them to lowercase and removing any characters that are not letters, digits, or specific Cyrillic
 * characters (а-
 * @returns The `compareStrings` function returns the result of comparing two cleaned and lowercase
 * strings `cleanStr1` and `cleanStr2` using the `localeCompare` method with the locale specified in
 * `DEFAULTS.locale`.
 */
export const compareStrings = (str1 = '', str2 = '') => {
	const cleanStr1 = str1.toLowerCase().replace(/[^\w\dа-яєії]|_/g, '');
	const cleanStr2 = str2.toLowerCase().replace(/[^\w\dа-яєії]|_/g, '');
	return cleanStr1.localeCompare(cleanStr2, DEFAULTS.locale);
};

/**
 * The function `rotateArrayLeft` takes an array and rotates it to the left by moving the first element
 * to the end.
 * @param {T[]}  - The `rotateArrayLeft` function takes an array of elements of type `T` as input and
 * rotates the array to the left by moving the first element to the end of the array. The function uses
 * destructuring to separate the first element from the rest of the array, then creates a new array
 */
export const rotateArrayLeft = <T extends unknown>([first, ...rest]: T[]) => [...rest, first];

/**
 * The function `dtFromYMD` takes a date string in the format YYYYMMDD and returns a Day.js object
 * representing that date, or the current date if the input is invalid or missing.
 * @param {string | null} [dateYMD] - The `dateYMD` parameter is a string representing a date in the
 * format "YYYYMMDD". It is optional and can be either a string or null.
 * @returns The function `dtFromYMD` returns a Day.js object representing the date parsed from the
 * input `dateYMD` string in the format 'YYYYMMDD' if it is valid. If the input is not valid or not
 * provided, it returns the current date at the start of the day.
 */
export const dtFromYMD = (dateYMD?: string | null) => {
	const dt = dayjs(dateYMD, YYYYMMDD);
	return dt.isValid() ? dt : dayjs().startOf('day');
};

/**
 * The function `getHumanDigitalDuration` takes a number of seconds as input and returns a formatted
 * duration in hours, minutes, and seconds.
 * @param {number} seconds - The `seconds` parameter is a number representing the duration in seconds
 * that you want to convert into a human-readable digital format.
 * @returns The function `getHumanDigitalDuration` returns a formatted duration based on the input
 * number of seconds. If the input is `Infinity`, it returns the string `'0:00'`. Otherwise, it uses
 * the `dayjs` library to create a duration object and then formats it as either minutes and seconds
 * (`'m:ss'`) if the duration is less than an hour, or hours, minutes
 */
export const getHumanDigitalDuration = (seconds: number) => {
	if (seconds === Infinity) return '0:00';
	const duration = dayjs.duration(seconds, 's');
	return duration.format(duration.hours() === 0 ? 'm:ss' : 'H:mm:ss');
};

export type TColorTheme = 'light' | 'dark';

/**
 * Storage
 */

// export type TStorageFields = 'theme';
// type TSearcherStorage = { [key in TStorageFields]: any };
interface ISearcherStorage {
	theme: TColorTheme;
	unRoute: string;
}
export const storage = new Storage<ISearcherStorage>('searcher');

export const updateThemeInPage = (theme?: TColorTheme) => {
	document.documentElement.dataset.bsTheme = storage.get('theme') || 'light';
};
