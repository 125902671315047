import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appReducer from './features/app/appSlice';
import searcherReducer from './features/searcher/searcherSlice';
import { searcherApi } from './services/searcherApi';

const store = configureStore({
	reducer: {
		app: appReducer,
		searcher: searcherReducer,
		[searcherApi.reducerPath]: searcherApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(searcherApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const selectAppStore = (state: RootState) => state.app;
export const selectSearcherStore = (state: RootState) => state.searcher;

export default store;
