import React, { useCallback, useEffect, useState } from 'react';
import { BsEyeSlashFill, BsNewspaper } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { addSource, changeSources, removeSource } from '../../features/app/appSlice';
import { RootState } from '../../store';
import WithSources, { IWithSources } from '../hoc/withSources';
import LinkButton from '../linkButton';
import TextIcon from '../textIcon';
import SourceList from './sourceList';
import SourceSelector from './sourceSelector';

interface ISourcesProps extends PropsFromRedux, IWithSources, React.AllHTMLAttributes<HTMLDivElement> {}
const Sources = ({ sources, changeSources, addSource, removeSource, sourcesAllIds, sourcesById }: ISourcesProps) => {
	const [excluded, setExcluded] = useState(sources.length !== 0 && Math.sign(sources[0]) === -1);

	useEffect(() => {
		setExcluded(sources.length !== 0 && Math.sign(sources[0]) === -1);
	}, [sources]);

	const onClearSourcesClick = useCallback(() => changeSources([]), [changeSources]);

	return (
		<div>
			<label htmlFor="pre_sources" className="form-label mb-0 d-flex">
				<TextIcon Icon={BsNewspaper}>Обрані джерела</TextIcon>
				<LinkButton
					className="text-danger ms-auto"
					title="Очистити"
					onClick={onClearSourcesClick}
					disabled={sources.length === 0}
				>
					<BsEyeSlashFill />
				</LinkButton>
			</label>
			<SourceSelector
				sourcesIds={sourcesAllIds || []}
				sourcesById={sourcesById}
				addSource={addSource}
				disabled={excluded}
				changeSources={changeSources}
			/>
			{sources.length !== 0 && (
				<SourceList
					sources={sources}
					removeSource={removeSource}
					sourcesIds={sourcesAllIds || []}
					sourcesById={sourcesById}
					excluded={excluded}
				/>
			)}
			{/* <PreSources changeSources={changeSources} /> */}
		</div>
	);
};

const mapState = (state: RootState) => ({
	sources: state.app.sources,
});

const mapDispatch = {
	changeSources,
	addSource,
	removeSource,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithSources(Sources));
