import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BsBriefcase, BsNewspaper, BsQuestionCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TVIcon from '../img/tv-app.webp';
import { DEFAULTS } from '../init';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import MessagesSearchForm from './MessagesSearchForm';
import TextIcon from './textIcon';

interface INavHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isAuthenticated: boolean;
	isLoading: boolean;
	hasAccess?: boolean;
}
const NavHeader = ({ isAuthenticated, isLoading, hasAccess }: INavHeaderProps) => {
	// const [isMdOrSmall] = useScreenWidth(DEFAULTS.screenMdBorder);
	return (
		<Navbar bg="primary" variant="dark" expand="md" fixed="top">
			<Container fluid>
				<Link to="/" className="navbar-brand fw-bolder">
					<BrandImage src={process.env.PUBLIC_URL + '/favicon.png'} />
					{DEFAULTS.pageTitle}
				</Link>
				<Navbar.Toggle area-controls="navbarSupportedContent" />
				{hasAccess ? (
					<Navbar.Collapse id="navbarSupportedContent">
						<Nav className="me-2">
							<Nav.Item>
								<Link to="/source" className="nav-link">
									<TextIcon Icon={BsNewspaper} className="d-md-none d-lg-inline" size="lg">
										Джерела
									</TextIcon>
								</Link>
							</Nav.Item>
							<Nav.Item>
								<Link to="/rubric" className="nav-link">
									<TextIcon Icon={BsBriefcase} className="d-md-none d-lg-inline" size="lg">
										Рубрики
									</TextIcon>
								</Link>
							</Nav.Item>
							<Nav.Link
								href={`https://tv.${process.env.REACT_APP_DOMAIN_NAME}`}
								target="_blank"
								className="hstack gap-1"
							>
								<NavImage src={TVIcon} className="d-md-none d-lg-inline" />
								<span>Телек</span>
							</Nav.Link>
							<Nav.Link href="https://fsmgp.gitbook.io/searcher/" target="_blank" className="text-nowrap">
								<BsQuestionCircle className="icon-lg mt-n1" />
								<span className="d-md-none d-xl-inline ms-2">Довідка</span>
							</Nav.Link>
						</Nav>
						<MessagesSearchForm />
						<Nav className="ms-auto">{isLoading || isAuthenticated ? <LogoutButton /> : <LoginButton />}</Nav>
					</Navbar.Collapse>
				) : (
					<Nav className="ms-auto">{isLoading || isAuthenticated ? <LogoutButton /> : <LoginButton />}</Nav>
				)}
			</Container>
		</Navbar>
	);
};

const BrandImage = styled.img`
	width: 40px;
	height: 40px;
	margin: -10px 0.25rem -6px 0;
	filter: drop-shadow(0 0 1px white);
`;

const NavImage = styled.img`
	width: auto;
	height: 20px;
	object-fit: contain;
	margin-top: 1px;
	margin-left: -2px;
	filter: saturate(0.9);
`;

export default NavHeader;
