import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import TextIcon from './textIcon';

interface ISearchFormProps extends React.AllHTMLAttributes<HTMLFormElement> {
	initTerm?: string;
	onTermChange?: (term: string) => void;
	onTermSubmit?: (term: string, checked?: boolean) => void;
	checkboxTitle?: string;
}
const SearchForm = ({
	initTerm = '',
	className,
	onTermSubmit,
	onTermChange,
	id,
	children,
	checkboxTitle,
}: ISearchFormProps) => {
	const [term, setTerm] = useState(initTerm);
	const refSearch = useRef<HTMLInputElement>(null);
	const [checked, setChecked] = useState<boolean>(false);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newTerm = event.target.value;
			setTerm(newTerm);
			if (onTermChange) onTermChange(newTerm);
		},
		[onTermChange]
	);

	const onSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		if (onTermSubmit) onTermSubmit(term, checked);
		refSearch.current?.focus();
		refSearch.current?.select();
	};

	const onCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	useEffect(() => {
		refSearch.current?.focus();
		setTimeout(() => refSearch.current?.select(), 0);
	}, []);

	useEffect(() => {
		setTerm(initTerm);
		// if (onTermChange) onTermChange(initTerm);
	}, [initTerm]);

	// useEffect(() => {
	// 	setChecked(false);
	// }, [checkboxTitle]);

	return (
		<Form
			id={id || 'fmSearch'}
			className={classNames('flex-grow-1 d-flex align-items-center', className)}
			role="search"
			onSubmit={onSubmit}
		>
			<InputGroup>
				{checkboxTitle && (
					<span className="input-group-text gap-1">
						<input
							type="checkbox"
							id="cbOnceSource"
							className="form-check-input m-0"
							checked={checked}
							onChange={onCheckboxClick}
							value=""
						/>
						<label htmlFor="cbOnceSource" className="form-check-label">
							{checkboxTitle}
						</label>
					</span>
				)}
				<Form.Control
					name="term"
					// accessKey="f"
					type="text"
					className="form-control"
					placeholder="введіть підстроку для пошуку..."
					maxLength={3900}
					autoComplete="off"
					value={term}
					// defaultValue={params.term}
					// onChange={onChangeSearchParam}
					onChange={onChange}
					ref={refSearch}
				/>
				<Button type="submit" variant="warning">
					<TextIcon Icon={BsSearch}>Знайти</TextIcon>
				</Button>
				{children}
			</InputGroup>
		</Form>
	);
};

export default SearchForm;
