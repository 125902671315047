import classNames from 'classnames';
import { ButtonGroup, DropdownButton } from 'react-bootstrap';
import { BsBarChartLine } from 'react-icons/bs';
import QueryLink from '../../components/queryLink';
import TextIcon from '../../components/textIcon';
import { STATISTIC_TABS } from '../../interfaces/statistic';

interface IStatisticButtonProps {
	title?: string | JSX.Element;
}
const StatisticButton = ({ title = 'Діаграми' }: IStatisticButtonProps) => (
	<DropdownButton
		as={ButtonGroup}
		id="ddDiagram"
		title={
			<TextIcon Icon={BsBarChartLine} className="mt-n1">
				{title}
			</TextIcon>
		}
		size="sm"
		variant="outline-primary"
		className="dropdown_flex"
	>
		{STATISTIC_TABS.map(({ route, title, Icon }) => (
			<QueryLink key={route} to={`statistic/${route}`} className={classNames('dropdown-item', !route && 'active')}>
				<TextIcon Icon={Icon}>{title}</TextIcon>
			</QueryLink>
		))}
	</DropdownButton>
);
export default StatisticButton;
