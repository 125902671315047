import React, { useEffect, useRef, useState } from 'react';
import { changeSearchMeta } from '../../features/searcher/searcherSlice';
import { useDebounce } from '../../hooks';
import SearchImage from '../../img/undraw_searching_re_3ra9.svg';
import { ISearchResponse } from '../../init';
import { TMessageListOrder } from '../../interfaces/params';
import { useGetMessagesQuery } from '../../services/messages';
import { selectSearcherStore, useAppDispatch, useAppSelector } from '../../store';
import FetchError from '../FetchError';
import Caption from '../caption';
import SearchWaiter from '../searchWaiter';

/**
 * В реалізації компоненту доволі багато вказівок лінтеру eslint-disable-line.
 * Це пов'язано з тим, що лінтер не розуміє того, що даний компонент є hoc-ом,
 * тому видає помилки про те, що React Hook "..." cannot be called inside a callback.
 */

export interface IWithSearchResponse {
	// fetchResult?: ReturnType<typeof useGetMessagesQuery>;
	searchResponse: ISearchResponse | undefined;
	messageOrder?: TMessageListOrder;
	pageNo: number;
	isFetching?: boolean;
	refetch?: () => void;
	withPagination?: boolean;
}
export type TOnMessagesFetchedFunction = (data: {
	searchResponse: ISearchResponse;
	countChanged: boolean;
	refetch: () => void;
}) => void;

interface IWithSearchResponseProps extends React.AllHTMLAttributes<HTMLDivElement> {
	queryString: string;
	pageNo: number;
	ts?: number;
	messageOrder?: TMessageListOrder;
	skip?: boolean;
	onFetched?: TOnMessagesFetchedFunction;
	/**
	 * Якщо повідомлення для чекання не передається, то waiter не виводиться
	 */
	waitMessage?: string;
	/**
	 * Тут мається на увазі мутація кількості знайдених повідомлень і порядку повідомлень
	 * (прямий, або зворотний). Якщо така мутація виконується, то буде виводитись
	 * плашка переходу між сторінками.
	 */
	mutateState?: boolean;
	/**
	 * Визначає, чи потрібно показувати компонент-заглушку в процесі першого пошуку
	 */
	showLoading?: boolean;
	pollingInterval?: number;
}
const WithSearchResponse =
	<T extends IWithSearchResponse>(WrappedComponent: React.ComponentType<T>) =>
	({
		queryString,
		pageNo,
		ts,
		messageOrder = 'D',
		skip = false,
		onFetched,
		waitMessage,
		mutateState = false,
		pollingInterval,
		showLoading = true,
		...rest
	}: Omit<T, keyof IWithSearchResponse> & IWithSearchResponseProps) => {
		// eslint-disable-next-line
		const { debouncedValue: debouncedQueryString } = useDebounce(queryString, 100);
		const dispatch = useAppDispatch(); // eslint-disable-line
		const { ts: stateTs } = useAppSelector(selectSearcherStore); // eslint-disable-line
		const [visiblePageNo, setVisiblePageNo] = useState(pageNo); // eslint-disable-line
		const [visibleMessageOrder, setVisibleMessageOrder] = useState(messageOrder); // eslint-disable-line
		// eslint-disable-next-line
		const { data, isFetching, isLoading, isUninitialized, error, refetch } = useGetMessagesQuery(
			{ queryString: debouncedQueryString, pageNo, ts: ts ?? stateTs },
			{ skip: skip || queryString !== debouncedQueryString || debouncedQueryString === '', pollingInterval }
		);
		const oldNumFound = useRef<number>(); // eslint-disable-line

		// eslint-disable-next-line
		useEffect(() => {
			oldNumFound.current = undefined;
		}, [debouncedQueryString]);

		// eslint-disable-next-line
		useEffect(() => {
			if (!data) return;

			if (onFetched)
				onFetched({
					searchResponse: data,
					countChanged: oldNumFound.current !== undefined && oldNumFound.current !== data.response?.numFound,
					refetch,
				});
			oldNumFound.current = data.response?.numFound;
			if (mutateState) {
				dispatch(changeSearchMeta({ numFound: data.response?.numFound, messageOrder }));
			}
			setVisiblePageNo(pageNo);
			setVisibleMessageOrder(messageOrder);
		}, [data, onFetched, mutateState, dispatch]); // eslint-disable-line

		return (
			<>
				{error && <FetchError error={error} />}
				{!data ? null : isLoading && showLoading ? (
					<Caption
						level={4}
						title={waitMessage}
						className="text-cool"
						imgSrc={SearchImage}
						// imgClassName="animation-bounce"
						alt="Зображення початку пошуку"
					/>
				) : (
					<WrappedComponent
						{...(rest as unknown as T)}
						searchResponse={data}
						pageNo={visiblePageNo}
						messageOrder={visibleMessageOrder}
						isFetching={isFetching}
						refetch={refetch}
						withPagination={mutateState}
					/>
				)}
				{isFetching && waitMessage && <SearchWaiter title={`${waitMessage}...`} />}
			</>
		);
	};

export default WithSearchResponse;
