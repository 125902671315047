import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import FetchError from '../../components/FetchError';
import TextIcon from '../../components/textIcon';
import UpdateButton from '../../components/updateButton';
import { scrollWindowToTop } from '../../scrolls';
import { useUkrnetSectionMessagesQuery } from '../../services/messages';
import { getDatasetAttr, getToday } from '../../utils';
import UkrnetMessage from './UkrnetMessage';

const MIN_MESSAGES_COUNT = 25;

interface IUkrnetSectionMessagesProps {
	section: string;
}
const UkrnetSectionMessages = ({ section }: IUkrnetSectionMessagesProps) => {
	const [fullList, setFullList] = useState(false);
	const [today, setToday] = useState(getToday());
	const [selectedMessageId, setSelectedMessageId] = useState(0);
	const { data, error, refetch } = useUkrnetSectionMessagesQuery(
		section ? { section, count: fullList ? undefined : MIN_MESSAGES_COUNT } : skipToken
	);

	useEffect(() => {
		setSelectedMessageId(0);
	}, [section]);

	useEffect(() => {
		if (!data) return;
		setToday(getToday());
	}, [data]);

	const onMoreMessagesClick = useCallback(() => {
		const newFullList = !fullList;
		setFullList(newFullList);
		if (!newFullList) scrollWindowToTop('auto');
	}, [fullList]);

	const onMsgClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const strUnId = getDatasetAttr(event, '.btn-un-msg', 'unId');
		if (!strUnId) return;
		const unId = parseInt(strUnId);
		setSelectedMessageId(unId === selectedMessageId ? 0 : unId);
	};

	return (
		<>
			{error && <FetchError error={error} />}
			{/* <div className="list-group list-group-flush" onClick={onMsgClick}> */}
			<UpdateButton onUpdate={refetch} updateIntervalSec={5 * 60} className="d-none" />
			<div className="vstack gap-2 m-2" onClick={onMsgClick}>
				{data &&
					data.map((message) => (
						<UkrnetMessage
							key={message.id}
							message={message}
							today={today}
							expanded={message.id === selectedMessageId}
						/>
					))}
			</div>
			<div className="text-center position-relative">
				<Button
					variant="link"
					className="stretched-link text-decoration-none p-0 link-secondary"
					onClick={onMoreMessagesClick}
				>
					<TextIcon Icon={fullList ? BsCaretUpFill : BsCaretDownFill}>{fullList ? 'Менше' : 'Більше'} новин</TextIcon>
				</Button>
			</div>
		</>
	);
};

export default UkrnetSectionMessages;
