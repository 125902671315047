import React from 'react';
import { Helmet } from 'react-helmet';
import { DEFAULTS } from '../init';

interface IPageTitleProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const PageTitle = ({ title, children }: IPageTitleProps) => {
	const prefix = title && title !== '«»' ? `${title} :: ` : '';
	return (
		<Helmet>
			<title>
				{prefix}
				{DEFAULTS.pageTitle}
			</title>
			{children}
		</Helmet>
	);
};

export default PageTitle;
