import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { DEFAULTS } from '../init';
import { EntityIcon } from '../styledComponents';

interface ISourceImageProps extends React.BaseHTMLAttributes<HTMLImageElement> {
	sourceId: number | string;
	size?: string;
	rounded?: string;
}
const SourceImage = React.memo(
	({ sourceId, className, rounded = 'rounded', size = '3em', ...rest }: ISourceImageProps) => {
		const [src, setSrc] = useState(() => `assets/source/${sourceId}`);

		const onLoadImageError = () => setSrc(DEFAULTS.srcImg);

		useEffect(() => {
			setSrc(`assets/source/${sourceId}`);
		}, [sourceId]);

		return (
			<EntityIcon
				size={size}
				className={classNames('flex-shrink-0', rounded, className)}
				src={src}
				onError={onLoadImageError}
				loading="lazy"
				{...rest}
			/>
		);
	}
);

export default SourceImage;
