import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BsSearch } from 'react-icons/bs';
import { DEFAULTS } from '../init';
import TextIcon from './textIcon';

interface ISearchWaiterProps extends React.AllHTMLAttributes<HTMLElement> {}
const SearchWaiter = ({ title = 'Пошук повідомлень...' }: ISearchWaiterProps) => {
	const INTERVAL = 500; // ms
	const [elapsed, setElapsed] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => setElapsed((elapsed) => elapsed + INTERVAL / 1000), INTERVAL);
		return () => clearInterval(interval);
	}, []);

	return (
		<Modal show={0 < elapsed} contentClassName="bg-warning" backdrop="static" centered dialogClassName="fit-content">
			<Modal.Body>
				<h5 className="my-2 d-flex justify-content-center align-items-center text-dark">
					<TextIcon Icon={BsSearch} className="animation-bounce">
						{title}
					</TextIcon>
					{elapsed !== 0 && (
						<span className="ms-2 badge rounded-pill text-bg-light">
							{elapsed.toLocaleString(DEFAULTS.locale, { minimumFractionDigits: 1 })} сек.
						</span>
					)}
				</h5>
			</Modal.Body>
		</Modal>
	);
};

export default SearchWaiter;
