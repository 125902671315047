import { useAuth0 } from '@auth0/auth0-react';
import React, { Suspense, useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Outlet } from 'react-router';
import { DEFAULTS, getTokenSilentlyAuthOptions } from '../init';
import { RootState } from '../store';
import NavFooter from './navFooter';
import NavHeader from './navHeader';

import jwtDecode from 'jwt-decode';
import { changePageIsVisible, changePermissions } from '../features/app/appSlice';
import { IAuth0AccessToken } from '../hooks';
import FixedActionPanel from './FixedActionPanel';
import Preloader from './Preloader';
import SplashScreen from './SplashScreen';

const LazyEmptyWorkPlace = React.lazy(() => import('./EmptyWorkPlace'));

interface IMainPageProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const MainPage = ({ params, hasAccess, changePermissions, colorTheme, changePageIsVisible }: IMainPageProps) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const oldHash = useRef(window.location.hash);

	useEffect(() => {
		const handleVisibilityChange = () => {
			changePageIsVisible(document.visibilityState === 'visible');
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
	}, [changePageIsVisible]);

	useEffect(() => {
		const newHash = window.location.hash;

		// Пустий хеш виникає з якоїсь причини після входу в обліковий запис:
		// пошук здійснюється, а хеш очищується. В цьому разі встановлюємо збережений хеш.
		if (!newHash) {
			if (isAuthenticated && oldHash.current) window.location.hash = oldHash.current;
		} else {
			if (oldHash.current !== newHash) oldHash.current = newHash;
		}
	}, [isAuthenticated]);

	useEffect(() => {
		const updatePermissions = async (isAuthenticated: boolean) => {
			const accessToken = isAuthenticated
				? await getAccessTokenSilently(getTokenSilentlyAuthOptions(DEFAULTS.availableScopes))
				: undefined;

			changePermissions(accessToken ? (jwtDecode(accessToken) as IAuth0AccessToken).scope.split(' ') : undefined);
		};

		updatePermissions(isAuthenticated);
	}, [isAuthenticated, getAccessTokenSilently, changePermissions]);

	if (!(isAuthenticated || isLoading)) return <SplashScreen />;

	return (
		<>
			{/* <PageTitle title={searching ? `Шукаємо «${params.term}»...` : undefined} /> */}
			<NavHeader isAuthenticated={isAuthenticated} isLoading={isLoading} hasAccess={hasAccess} />
			{hasAccess ? (
				<Outlet />
			) : hasAccess === false ? (
				<Suspense fallback={<Preloader />}>
					<LazyEmptyWorkPlace />
				</Suspense>
			) : null}
			<NavFooter hasAccess={hasAccess} colorTheme={colorTheme} />
			<FixedActionPanel hasAccess={hasAccess} />
		</>
	);
};

const mapState = (state: RootState) => ({
	params: state.app.searchParams,
	hasAccess: state.app.hasAccess,
	colorTheme: state.app.colorTheme,
});

const mapDispatch = {
	changePermissions,
	changePageIsVisible,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MainPage);
