import dayjs from 'dayjs';
import { useEffect } from 'react';

const LIVE_TIME_INTERVAL = 30 * 1000;
const LiveTimeController = () => {
	useEffect(() => {
		const intervalHandle = setInterval(updateLiveTime, LIVE_TIME_INTERVAL);
		return () => clearInterval(intervalHandle);
	}, []);

	return null;
};

export default LiveTimeController;

const updateLiveTime = () => {
	// const event = new CustomEvent(LIVE_TIME_EVENT_NAME);
	// document.dispatchEvent(event);
	const today = dayjs();
	const timeControls = document.querySelectorAll('.live-time');

	timeControls.forEach((element) => {
		const { time, showTime } = (element as HTMLElement).dataset;
		const dt = dayjs(time);
		element.innerHTML =
			dt.fromNow() + (showTime === '1' ? ` (${dt.format(dt.isSame(today, 'date') ? 'LT' : 'L LT')})` : '');
	});
};
