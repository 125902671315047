import React, { Suspense } from 'react';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import LiveTimeController from '../../components/LiveTimeController';
import PageNotFound from '../../components/PageNotFound';
import Preloader from '../../components/Preloader';
import MainPage from '../../components/mainPage';
import QueryNavigate from '../../components/queryNavigate';
import { STATISTIC_TABS } from '../../interfaces/statistic';
import MessageDialog from '../messages/MessageDialog';
import RubricMessageList from '../rubrics/RubricMessageList';
import AccessTokenUpdater from './AccessTokenUpdater';
import MainLayout from './MainLayout';

const LazyStatistic = React.lazy(() => import('../statistic/statistic'));
const LazySourcesLayout = React.lazy(() => import('../sources/SourcesLayout'));
const LazySourceMessageList = React.lazy(() => import('../sources/SourceMessageList'));
const LazyRubricsLayout = React.lazy(() => import('../rubrics/RubricsLayout'));
const LazySearchLayout = React.lazy(() => import('../searcher/SearchLayout'));

const postRoute = {
	path: 'post/:postId',
	element: <MessageDialog />,
};
const statisticRoute = {
	path: 'statistic',
	// element: <Outlet />,
	children: [
		{
			path: ':diagram',
			element: (
				<Suspense fallback={<Preloader />}>
					<LazyStatistic />
				</Suspense>
			),
		},
		{
			index: true,
			element: <QueryNavigate to={STATISTIC_TABS[0].route} replace />,
		},
	],
};

const router = createHashRouter([
	{
		path: '/',
		element: <MainPage />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: 'home',
				element: <MainLayout />,
				children: [postRoute],
			},
			{
				path: 'search',
				// element: <SearchLayout />,
				element: (
					<Suspense fallback={<Preloader />}>
						<LazySearchLayout />
					</Suspense>
				),
				children: [postRoute, statisticRoute],
			},
			{
				path: 'source',
				element: (
					<Suspense fallback={<Preloader />}>
						<LazySourcesLayout />
					</Suspense>
				),
				children: [
					{
						path: ':sourceId',
						element: (
							<Suspense fallback={<Preloader />}>
								<LazySourceMessageList />
							</Suspense>
						),
						children: [postRoute],
					},
				],
			},
			{
				path: 'rubric',
				element: (
					<Suspense fallback={<Preloader />}>
						<LazyRubricsLayout />
					</Suspense>
				),
				children: [
					{
						path: ':rubricId',
						element: <RubricMessageList />,
						children: [postRoute, statisticRoute],
					},
				],
			},
			{
				index: true,
				element: <QueryNavigate to="home" replace />,
			},
			{
				path: '*',
				element: <PageNotFound />,
				// element: <QueryNavigate to="search" replace />,
			},
		],
	},
]);

// interface IAppProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const App = () => (
	<>
		<RouterProvider router={router} />
		<AccessTokenUpdater />
		<LiveTimeController />
	</>
);

export default App;
