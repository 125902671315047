import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ICalendarData, getMinutesFromTZ, rotateArrayLeft } from '../../init';
import CalendarDateItem from './CalendarDateItem';

interface ICalendarGrigProps {
	firstDoW: number;
	data?: ICalendarData[];
	// onDayClick: (date: Partial<IDate>) => void;
	selectedDate: Dayjs; // Тут встановлено час 12:00
	linkState?: any;
	emptyGrid?: boolean;
	TZ?: string;
}
const CalendarGrig = ({ firstDoW, data, selectedDate, linkState, emptyGrid: fakeGrid, TZ }: ICalendarGrigProps) => {
	// const onClick = (event: React.MouseEvent<HTMLElement>) => {
	// 	const element = (event.target as HTMLElement).closest('.calendar__day') as HTMLElement;
	// 	if (!element) return;
	// 	event.preventDefault();
	// 	event.stopPropagation();
	// 	const { day } = element.dataset;
	// 	if (!day) return;
	// 	onDayClick({ day: parseInt(day) });
	// };

	const [searchParams] = useSearchParams();

	if (data && data.length === 0) return null;

	const current = dayjs().startOf('day').add(12, 'hours');
	const year = selectedDate.year();
	const month = selectedDate.month();
	const tzShiftInMinutes = getMinutesFromTZ(TZ);

	return (
		<Grid className="rounded">
			<CalendarWeek />
			{(!fakeGrid && data
				? data
				: new Array(selectedDate.daysInMonth()).fill(undefined).map((_, index) => ({
						date: new Date(year, month, index + 1).toISOString(),
						count: 0,
				  }))
			).map(({ date, count }, index) => {
				const dt = TZ ? dayjs.utc(date).utcOffset(tzShiftInMinutes) : dayjs(date);

				// const dt = dayjs(date.replace('Z', timeShift));
				// console.log(dt.toISOString());

				return (
					<CalendarDateItem
						key={date}
						date={dt}
						TZ={TZ}
						count={count}
						startShift={index === 0 ? firstDoW : undefined}
						selected={dt.add(12, 'hours').isSame(selectedDate, 'date')}
						today={dt.add(12, 'hours').isSame(current, 'date')}
						linkState={linkState}
						searchParams={searchParams}
					/>
				);
			})}
		</Grid>
	);
};

export default CalendarGrig;

const Grid = styled.div`
	padding: 0.5em;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-auto-flow: row;
	gap: 0.5em 1em;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0.05);
`;

const CalendarWeek = () => (
	<>
		{rotateArrayLeft<string>([...dayjs.weekdaysMin()]).map((dow, index) => (
			<CalendarDateItem
				key={dow}
				dayClassName={classNames('calendar__week', 4 < index && 'calendar__week_em')}
				weekDay={dow}
			/>
		))}
	</>
);
