import React from 'react';
import { IconType } from 'react-icons';
import styled from 'styled-components';
import TextIcon from './textIcon';

interface IFixedPanelButtonProps extends React.AllHTMLAttributes<HTMLDivElement> {
	Icon: IconType;
	onClick: () => void;
}
const FixedPanelButton = ({ Icon, onClick, ...rest }: IFixedPanelButtonProps) => {
	return (
		<ButtonContainer {...(rest as any)}>
			<ButtonButton className="btn p-0" onClick={onClick}>
				<ButtonIcon Icon={Icon} />
			</ButtonButton>
		</ButtonContainer>
	);
};

export default FixedPanelButton;

const ButtonContainer = styled.div`
	z-index: var(--bs-modal-zindex);
`;

const ButtonButton = styled.button`
	line-height: 0;
	opacity: var(--fixed-button-opacity, 0.5);
	transition: opacity 0.3s;
	&:hover {
		opacity: 1;
	}
`;

const ButtonIcon = styled(TextIcon)`
	font-size: 24px;
	color: var(--fixed-button-color, gray);
`;
