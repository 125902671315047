import React from 'react';
import { TStringWithUndefined } from '../../init';
import { IRubricDic } from '../../interfaces/rubrics';
import { useGetRubricQuery } from '../../services/rubrics';

interface IWithRubricCommon {
	rubricId: TStringWithUndefined;
}
export interface IWithRubric extends IWithRubricCommon {
	rubric: IRubricDic | undefined;
	rubricIsFetching: boolean;
	rubricError: any;
}

const WithRubric =
	<T extends IWithRubric>(WrappedComponent: React.ComponentType<T>) =>
	({ rubricId = '0', ...rest }: Omit<T, keyof IWithRubric> & IWithRubricCommon) => {
		// eslint-disable-next-line
		const { data, error, isFetching } = useGetRubricQuery(rubricId);

		return (
			<WrappedComponent
				{...(rest as T)}
				rubricId={rubricId}
				rubric={data}
				rubricIsFetching={isFetching}
				rubricError={error}
			/>
		);
	};

export default WithRubric;
