import React from 'react';
import { IDictionary, TEntityId } from '../../interfaces/common';
import { ISourceDic } from '../../interfaces/dict';
import { useDictionariesQuery } from '../../services/searcherApi';

export interface IWithSources {
	sourcesAllIds: TEntityId<number>[];
	sourcesById: IDictionary<ISourceDic>;
	sourcesTop: TEntityId<number>[];
}
const WithSources =
	<T extends IWithSources>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithSources>) => {
		// eslint-disable-next-line
		const { sources } = useDictionariesQuery(undefined, {
			selectFromResult: ({ data }) => ({ sources: data?.sources }),
			refetchOnMountOrArgChange: false,
		});

		return (
			<WrappedComponent
				{...(props as T)}
				sourcesAllIds={sources?.ids || []}
				sourcesTop={sources?.top || []}
				sourcesById={sources?.entities || {}}
			/>
		);
	};

export default WithSources;
