import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { BsEyeSlash, BsInfoCircleFill } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../alerts';
import EntityTitle from '../../components/EntityTitle';
import FetchError from '../../components/FetchError';
import RubricImage from '../../components/RubricImage';
import WithRubric, { IWithRubric } from '../../components/hoc/withRubric';
import { changeParams } from '../app/appSlice';

interface IRubricInfoProps extends IWithRubric, React.AllHTMLAttributes<HTMLDivElement> {
	inParamsContainer?: boolean;
}
const RubricInfo = ({
	rubric,
	rubricIsFetching: isFetching,
	rubricError: error,
	className,
	inParamsContainer = false,
}: IRubricInfoProps) => {
	const dispatch = useDispatch();

	const onFilterClick = useCallback(() => {
		if (!rubric) return;
		showAlert(rubric.QUERY || '', 'Пошуковий запит');
	}, [rubric]);

	const onRubricClearClick = useCallback(() => {
		dispatch(changeParams({ rubric: '' }));
	}, [dispatch]);

	if (inParamsContainer)
		return (
			<div className={classNames('hstack gap-1', className)}>
				{isFetching ? (
					<>
						<Skeleton circle style={{ width: '2em', height: '2em' }} inline containerClassName="lh-1 flex-shrink-0" />
						<Skeleton width="62%" inline containerClassName="flex-grow-1" />
					</>
				) : rubric ? (
					<>
						<RubricImage src={rubric.ICON} size="2em" withShadow={false} className="align-self-start flex-shrink-0" />
						<span className="lh-sm">{rubric.TITLE}</span>
						<Button variant="link" className="p-0 ms-auto link-danger" onClick={onRubricClearClick}>
							<BsEyeSlash />
						</Button>
					</>
				) : (
					error && <FetchError error={error} />
				)}
			</div>
		);
	return (
		<div className={classNames('vstack align-items-center gap-1', className)}>
			{isFetching ? (
				<>
					<Skeleton containerClassName="fs-4 lh-sm w-100 text-center" width="62%" inline />
					<Skeleton circle style={{ width: 150, height: 150 }} inline containerClassName="lh-1" />
				</>
			) : rubric ? (
				<>
					<EntityTitle>
						Рубрика: &laquo;{rubric.TITLE}&raquo;
						<Button
							variant="link"
							className="p-0 link-secondary ms-1"
							onClick={onFilterClick}
							title="Переглянути пошуковий запит"
						>
							<BsInfoCircleFill className="mt-n1" />
						</Button>
					</EntityTitle>
					<RubricImage src={rubric.ICON} size="150px" />
				</>
			) : (
				error && <FetchError error={error} />
			)}
		</div>
	);
};

export default RubricInfo;
export const RubricInfoById = WithRubric(RubricInfo);
