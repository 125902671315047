import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import LiveTime from '../../components/LiveTime';
import QueryLink from '../../components/queryLink';
import SourceImage from '../../components/sourceImage';
import { DEFAULTS, IMessage, removeFakeTags } from '../../init';
import { ISourceDic } from '../../interfaces/dict';

interface IMessageMediumProps extends React.AllHTMLAttributes<HTMLDivElement> {
	message: IMessage;
	source?: ISourceDic;
	// index: number;
}
const MessageMedium = ({
	message: { id, title, hit_date, source_id, url, source_title, body },
	className,
	source,
}: IMessageMediumProps) => {
	const [text, setText] = useState<string>();

	const sourceTitle = source?.title || source_title;
	const dt = dayjs(hit_date);

	useEffect(() => {
		if (!body) return setText(undefined);
		const paragraphs = body.split('\n')?.filter((snippet) => snippet.trim());
		setText(
			paragraphs.reduce(
				(accumulator, s, index) =>
					(accumulator += `<p>${removeFakeTags(s)}${index === paragraphs.length - 1 ? '&hellip;' : ''}</p>`),
				''
			)
		);
	}, [body]);

	return (
		<div className={classNames('vstack', className)}>
			<div className="position-relative mt-1">
				<h4 className="mb-2 pb-2 border-bottom border-secondary">
					<QueryLink
						to={`post/${id}`}
						className="text-decoration-none stretched-link message-title"
						// className="link-offset-2 text-decoration-on-hover link-body-emphasis stretched-link"
						// className="link-offset-2 link-underline-opacity-0 link-underline-opacity-10-hover link-body-emphasis stretched-link"
						state={DEFAULTS.fromListState}
					>
						{title}
					</QueryLink>
				</h4>
				{text && (
					<div className="msg-md__snippets text-break">
						<span className="float-start">
							<LiveTime timestamp={hit_date} className="text-muted" />
							<span className="mx-1">&mdash;</span>
						</span>
						<div className="msg-md__snippets-text" dangerouslySetInnerHTML={{ __html: text }} />
					</div>
				)}
			</div>
			<div className="d-flex align-items-center gap-1 text-secondary justify-content-center position-relative mt-1">
				<SourceImage sourceId={source_id} title={sourceTitle} size="1.5em" />
				<span className="text-truncate">{sourceTitle}</span>
				<span>•</span>
				<span className="text-nowrap">
					<span>{dt.format('LT')}</span>
				</span>
				{url && (
					<>
						<span>•</span>
						<a
							target="_blank"
							rel="noreferrer"
							className="text-decoration-none link-secondary stretched-link"
							href={url}
						>
							<BsBoxArrowUpRight className="mt-n1" />
						</a>
					</>
				)}
			</div>
		</div>
	);
};

export default MessageMedium;
