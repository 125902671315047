import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { getStatDateGap } from '../../facetUtils';
import {
	currentYMD,
	DEFAULTS,
	dtFromYMD,
	getLocaleStrDate,
	prepareSearchTerm,
	storage,
	TNumberWithUndefined,
	TStringWithUndefined,
	USER_GMT,
	YYYYMMDD,
} from '../../init';
import { TObjectType } from '../../interfaces/common';
import {
	DEFAULT_SEARCH_PARAMS,
	ISearchParams,
	PERIOD_OPTIONS,
	SORT_OPTIONS,
	TMessageListOrder,
} from '../../interfaces/params';
import { IStatisticTab } from '../../interfaces/statistic';

export interface IFacetRangeDateParams {
	'facet.range.gap': string;
	'facet.range.start': string;
	'facet.range.end': string;
}
export interface ISearcherSlice {
	queryString: string;
	pageNo: number;
	pageCount: number;
	ts: number;
	searchedTerm: string;
	facetRangeDateParams?: IFacetRangeDateParams;
	preparedSearchString: string;
	messageOrder: TMessageListOrder;
	selectedYMD: TStringWithUndefined; // Поточна обрана дата в форматі YYYY-MM-DD
	selectedDateIsToday: boolean | undefined;
	unSelectedRoute: string;
}
const initialState: ISearcherSlice = {
	queryString: '',
	pageNo: 0,
	pageCount: 0,
	ts: new Date().valueOf(),
	searchedTerm: '',
	facetRangeDateParams: undefined,
	preparedSearchString: '',
	messageOrder: SORT_OPTIONS[parseInt(DEFAULT_SEARCH_PARAMS.sort_index)].messageOrder,
	selectedYMD: undefined,
	selectedDateIsToday: undefined,
	unSelectedRoute: storage.get('unRoute') || '',
};
export const searcherSlice = createSlice({
	name: 'searcher',
	initialState,
	reducers: {
		changeQueryString: (state, action: PayloadAction<{ params: ISearchParams; updateTs?: boolean }>) => {
			// Если поисковая строка пустая, то обнуляем запрос для того, чтобы исключить поиск
			const newParams = action.payload.params;
			state.preparedSearchString = prepareSearchTerm(newParams.term || '');
			state.queryString = queryStringForSearchParams(newParams);
			state.facetRangeDateParams = getFacetRangeDate(
				parseInt(newParams.period_index),
				newParams.start_date,
				newParams.end_date
			);
			state.pageNo = 0;
			// state.pageCount = 0;
			if (action.payload.updateTs) state.ts = new Date().valueOf();
			state.searchedTerm = newParams.term.trim();
		},
		/**
		 * На відміну від попередньої функції змінює значення безпосередньо queryString,
		 * як строкової змінної
		 */
		changeRawQueryString: (state, action: PayloadAction<string>) => {
			if (state.queryString === action.payload) return;
			state.queryString = action.payload;
			state.pageNo = 0;
		},
		changeFacetRangeDateParams: (state, action: PayloadAction<IFacetRangeDateParams | undefined>) => {
			state.facetRangeDateParams = action.payload ? { ...action.payload } : undefined;
		},
		changeTs: (state) => {
			state.pageNo = 0;
			state.ts = new Date().valueOf();
		},
		changeSelectedYMD: (state, action: PayloadAction<TStringWithUndefined>) => {
			if (action.payload !== undefined) {
				const newDt = dtFromYMD(action.payload);
				if (newDt.format(YYYYMMDD) !== state.selectedYMD) {
					state.pageNo = 0;
					state.selectedYMD = newDt.format(YYYYMMDD);
					state.selectedDateIsToday = newDt.isSame(dayjs(), 'day');
				}
			} else {
				state.pageNo = 0;
				state.selectedYMD = undefined;
				state.selectedDateIsToday = undefined;
			}
		},
		changePageNo: (state, action: PayloadAction<number>) => {
			const newPageNo = getCorrectedPageNo(action.payload, state.pageCount);
			if (newPageNo === state.pageNo) return;
			state.pageNo = newPageNo;
		},
		/**
		 * @param action payload приймає кількість знайдених документів та новий порядок нумерації повідомлень
		 */
		changeSearchMeta: (
			state,
			action: PayloadAction<{ numFound: TNumberWithUndefined; messageOrder: TMessageListOrder }>
		) => {
			const newPageCount = Math.ceil((action.payload.numFound ?? 0) / DEFAULTS.messagesPerPage);
			state.pageCount = 0 <= newPageCount ? newPageCount : 0;
			state.messageOrder = action.payload.messageOrder;
		},
		clearSearchState: (state) => {
			state.pageCount = 0;
			state.pageNo = 0;
			state.selectedYMD = undefined;
			state.selectedDateIsToday = undefined;
			state.queryString = '';
			state.preparedSearchString = '';
			state.facetRangeDateParams = undefined;
		},
		changePreparedSearchString: (state, action: PayloadAction<string>) => {
			state.preparedSearchString = action.payload;
		},
		// changeMessageOrder: (state, action: PayloadAction<TMessageListOrder>) => {
		// 	state.messageOrder = action.payload;
		// },
		changeUnSelectedRoute: (state, action: PayloadAction<string>) => {
			state.unSelectedRoute = action.payload;
			storage.set({ unRoute: action.payload });
		},
	},
});

const getCorrectedPageNo = (newPageNo: number, pageCount: number | undefined) => {
	if (newPageNo <= 0 || pageCount === undefined) return 0;
	if (pageCount <= newPageNo) return pageCount - 1;
	return newPageNo;
};

export const {
	changeQueryString,
	changePageNo,
	changeRawQueryString,
	changeSelectedYMD,
	clearSearchState,
	changePreparedSearchString,
	changeTs,
	changeSearchMeta,
	changeUnSelectedRoute,
	changeFacetRangeDateParams,
} = searcherSlice.actions;

// export interface ISearchRequestParams {
// 	queryString: string;
// 	pageNo?: number;
// 	accessToken: string;
// 	messageOrder?: TMessageListOrder;
// }
// export const search =
// 	({ queryString, pageNo = 0, accessToken, messageOrder = 'D' }: ISearchRequestParams) =>
// 	async (dispatch: AppDispatch) => {
// 		if (queryString === '') return;

// 		scrollWindowToTop('smooth');
// 		const params = `${queryString}&page=${pageNo}`;

// 		dispatch(changeSearching(true));
// 		// await promiseTimeOut(2000);
// 		try {
// 			const response = await fetch(`api/search?${params}`, {
// 				headers: {
// 					Authorization: `Bearer ${accessToken}`,
// 				},
// 			});
// 			dispatch(changeSearching(false));
// 			if (!response.ok) {
// 				throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
// 			}
// 			const result: ISearchResponse = await response.json();
// 			dispatch(changeSearchResponse({ searchResponse: result, pageNo, messageOrder }));
// 			// dispatch(changeCurrentMessageOrder(messageOrder));
// 		} catch (error) {
// 			showSystemError(error as any);
// 		}
// 	};

export const getFacetRangeDate = (
	periodIndex: number,
	start_date: TStringWithUndefined,
	end_date: TStringWithUndefined
): IFacetRangeDateParams | undefined => {
	if (
		periodIndex === DEFAULTS.userCustomPeriodIndex &&
		new Date(end_date || currentYMD()).getTime() < new Date(start_date || currentYMD()).getTime()
	)
		return undefined;

	// В даному випадку можливо було б використовувати utc для поточного момента,
	// однак в даному випадку будуть помилки з часом для поясів західніше Гринвіча.
	// const [startDate, endDate] = getPeriodDates(periodIndex, start_date, end_date, new Date().toISOString());
	const [startDate, endDate] = getPeriodDates(periodIndex, start_date, end_date, 'NOW');

	return {
		'facet.range.gap':
			periodIndex === DEFAULTS.userCustomPeriodIndex
				? getStatDateGap(start_date, end_date)
				: PERIOD_OPTIONS[periodIndex].gap || '+1DAY',
		'facet.range.start': startDate || 'NOW-100YEARS/DAY',
		'facet.range.end': endDate,
	};
};

export const getSearchStatisticParams = (
	queryString: string,
	{ isRange, field }: IStatisticTab,
	facetRangeDateParams: IFacetRangeDateParams | undefined
) => {
	const facetParams: TObjectType = {
		facet: 'true',
		rows: '0',
		'facet.mincount': '1',
	};
	if (isRange) {
		facetParams['facet.range'] = field;
		Object.assign(facetParams, facetRangeDateParams);
		// facetParams = facetParams.concat([['facet.range', field], ...facetRangeDateParams]);
	} else {
		Object.assign(facetParams, {
			'facet.field': field,
			// ['facet.limit', '-1'],
			'facet.sort': 'count',
		});
	}

	return `${queryString}&facet=${encodeURIComponent(new URLSearchParams(facetParams).toString())}`;
};

const queryStringForSearchParams = ({
	period_index,
	start_date,
	end_date,
	country,
	sources,
	sort_index,
	term,
	severity,
	rubric,
}: ISearchParams) => {
	if (!term.trim()) return '';

	// pageNo добавляется в запрос непосредственно при его выполнении
	const params = new URLSearchParams({
		term: prepareSearchTerm(term),
		severity,
		TZ: USER_GMT,
	});

	const periodIndex = parseInt(period_index);
	if (periodIndex !== 0)
		params.append('period', 'hit_date:[' + getPeriodDates(periodIndex, start_date, end_date).join(' TO ') + ']');

	if (country !== '') params.append('country', `country:(${country})`);
	if (sources !== '') params.append('sources', `source_id:(${sources})`);
	if (rubric !== '') params.append('rubric_id', rubric);

	// params.append('sortIndex', sort_index); // Потрібно для пошуку відео
	const sortIndex = parseInt(sort_index);
	if (SORT_OPTIONS[sortIndex].value) {
		params.append('sort', SORT_OPTIONS[sortIndex].value);
		// if (!SORT_OPTIONS[sortIndex].value.startsWith('score')) {
		// 	params.set('severity', '100');
		// }
	}
	if (/\?|\*/.test(term)) params.set('severity', '0');

	return params.toString();
};

export const getPeriodDates = (
	periodIndex: number,
	start_date: TStringWithUndefined,
	end_date: TStringWithUndefined,
	defaultEndDate: string = '*'
) => {
	return periodIndex !== DEFAULTS.userCustomPeriodIndex
		? [PERIOD_OPTIONS[periodIndex].start(), PERIOD_OPTIONS[periodIndex].end || defaultEndDate]
		: [
				new Date(getLocaleStrDate(start_date || currentYMD())).toISOString(),
				`${new Date(getLocaleStrDate(end_date || currentYMD())).toISOString()}+1DAY-1SECOND`,
		  ];
};

export default searcherSlice.reducer;
