import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import TextIcon from '../../components/textIcon';
import { YYYYMMDD } from '../../init';

interface IMonthYearSelectorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	selectedDate: Dayjs;
	linkState?: any;
}
const MonthYearSelector = ({ selectedDate, children, className, linkState }: IMonthYearSelectorProps) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const changeSearchDate = ({ dYear = 0, dMonth = 0 }: { dYear?: number; dMonth?: number }) => {
		const newDate = selectedDate.add(dYear, 'year').add(dMonth, 'month');
		const newSearchParams = new URLSearchParams(searchParams);
		if (newDate.isSame(dayjs().startOf('day'), 'day')) newSearchParams.delete('date');
		else newSearchParams.set('date', newDate.format(YYYYMMDD));
		setSearchParams(newSearchParams, { replace: true, state: linkState });
	};

	const onYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newYear = parseInt(event.target.value);
		if (isNaN(newYear)) return;
		changeSearchDate({ dYear: newYear - selectedDate.year() });
	};

	const onMonthChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newMonth = parseInt(event.target.value);
		if (isNaN(newMonth)) return;
		changeSearchDate({ dMonth: newMonth - selectedDate.month() });
	};

	const onMonthShiftCLick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const dMonth = parseInt((event.currentTarget as HTMLElement).dataset.delta || '0');
		changeSearchDate({ dMonth });
	};

	const onTodayClick = async () => {
		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.delete('date');
		setSearchParams(newSearchParams, { replace: true, state: linkState });
	};

	const monthLinkFormat = `MMMM${
		selectedDate.isSame(dayjs(), 'year') && ![0, 11].includes(selectedDate.month()) ? '' : ' YYYY'
	}`;

	return (
		<div className={className}>
			<Row className="mb-2">
				<Col xs={7}>
					<Form.Select value={selectedDate.month()} onChange={onMonthChange}>
						{dayjs.months().map((month, index) => (
							<option key={index} value={index}>
								{month}
							</option>
						))}
					</Form.Select>
				</Col>
				<Col xs={5}>
					<Form.Control
						placeholder="Рік"
						type="number"
						min={2022}
						max={dayjs().year()}
						value={selectedDate.year()}
						onChange={onYearChange}
					/>
				</Col>
			</Row>
			{children}
			<div className="d-flex justify-content-between mt-1">
				<Button variant="link" className="p-0 text-secondary" data-delta={-1} onClick={onMonthShiftCLick}>
					<TextIcon Icon={BsArrowLeft}>
						{selectedDate.startOf('month').subtract(1, 'month').format(monthLinkFormat)}
					</TextIcon>
				</Button>
				<Button variant="link" onClick={onTodayClick} className="p-0 text-secondary">
					Сьогодні
				</Button>
				<Button variant="link" className="p-0 text-secondary" data-delta={1} onClick={onMonthShiftCLick}>
					<TextIcon Icon={BsArrowRight} iconLast>
						{selectedDate.startOf('month').add(1, 'month').format(monthLinkFormat)}
					</TextIcon>
				</Button>
			</div>
		</div>
	);
	// return <div>{selectedDate.format('MMMM YYYY')}</div>;
};

export default MonthYearSelector;
