import classNames from 'classnames';
import React from 'react';
import { DEFAULTS } from '../../init';

interface ISearchHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	numFound: number;
	searchTime?: number;
}
const SearchHeader = ({
	numFound,
	searchTime,
	title = 'Приблизна кількість результатів',
	children,
	className,
}: ISearchHeaderProps) => (
	<div className={classNames('d-flex justify-content-between w-100', className)}>
		<div className="text-muted">
			{title}:<span className="mx-1">{numFound.toLocaleString(DEFAULTS.locale)}</span>
			{searchTime !== undefined && (
				<span title="Тривалість пошуку">
					({(searchTime / 1000).toLocaleString(DEFAULTS.locale, { maximumSignificantDigits: 3 })} с)
				</span>
			)}
		</div>
		{children}
	</div>
);

export default SearchHeader;
