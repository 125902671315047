import dayjs from 'dayjs';
import { DEFAULTS, currentYMD } from './init';
import { TObjectType } from './interfaces/common';
import { DEFAULT_SEARCH_PARAMS, ISearchParams } from './interfaces/params';

/**
 * Определяет, изменились ли значения полей объекта target по сравнению с объектом source.
 * Ключи перебираются по объекту source
 * Если источника нет, то считаем, что объекты различаются
 * @param source Исходный объект
 * @param target Сравниваемый объект
 * @param strict Если равно true, то производит строгое сравнение, иначе - нет
 */
export const targetIsNotEquals = (source: TObjectType | undefined, target: TObjectType) =>
	source === undefined || Object.keys(source).some((key) => source[key] !== target[key]);

/**
 * Повертає об'єкт розбіжностей між двома об'єктами для передачі на сервер.
 * Примітка: в БД логічний тип представлено значеннями 1 та 0.
 * @param source Исходный объект
 * @param target Объект после редактирования
 * @param REQUIRED_FIELDS Названия полей, которые обязательно быть включены в пакет на отправку
 */
export const getDataForSave = <T extends TObjectType, Q extends string>(
	source: T | undefined,
	target: T,
	REQUIRED_FIELDS: string[] = [],
	nullIfEmpty = true
): Partial<T> & Pick<T, Q> => {
	const result = { ...target };
	if (source !== undefined) {
		Object.keys(source).forEach((key) => {
			if (!REQUIRED_FIELDS.includes(key) && source[key] === result[key]) delete result[key];
		});
	}
	if (nullIfEmpty)
		for (const key in result) {
			if (result[key] === '') (result[key] as any) = null;
		}
	return result;
};

const downloadData = (data: string, fileBaseName: string, fileExtension: string) => {
	const mime = { txt: 'text/plain', csv: 'text/csv' }[fileExtension] || 'text/plain';
	const blob = new Blob([data], { type: mime });
	const objectUrl = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.setAttribute('href', objectUrl);
	link.setAttribute('download', `${fileBaseName}.${fileExtension}`);
	link.textContent = 'download';
	link.className = 'd-none';
	const body = document.querySelector('body');
	if (!body) return;
	body.appendChild(link);
	link.click();
	setTimeout(() => {
		body.removeChild(link);
		URL.revokeObjectURL(objectUrl);
	}, 5000);
};

interface IDownloadArrayOfArraysParams {
	data: any[][];
	delimiter?: string;
	quote?: boolean;
	name?: string;
	extension?: string;
}
export const downloadArrayOfArrays = ({
	data,
	delimiter = '\t',
	quote = false,
	name = `magna_${new Date().valueOf()}`,
	extension = 'txt',
}: IDownloadArrayOfArraysParams) => {
	const rows: string[] = [];
	data.forEach((row) => {
		const strRow = quote ? row.map((s) => `"${s.toString()}"`) : row;
		rows.push(strRow.join(delimiter));
	});
	downloadData(rows.join('\n'), name, extension);
};

/**
 * The function `getDatasetAttr` retrieves a specified attribute value from the dataset of the closest
 * element matching a given selector in response to a mouse event.
 * @param event - The `event` parameter is a React MouseEvent object that represents the event that
 * occurred, such as a click or hover event on an element.
 * @param {string} selector - The `selector` parameter is a string that represents a CSS selector used
 * to find the closest ancestor element of the target element that matches the selector.
 * @param {string} attrName - The `attrName` parameter in the `getDatasetAttr` function refers to the
 * name of the custom data attribute that you want to retrieve from the element specified by the
 * `selector`. This custom data attribute should be present in the dataset of the element.
 * @returns The function `getDatasetAttr` returns the value of the specified attribute (`attrName`)
 * from the dataset of the closest element matching the provided selector in relation to the target
 * element of the mouse event. If no matching element is found, it returns `undefined`.
 */
export const getDatasetAttr = <T extends HTMLElement, Q extends HTMLElement>(
	event: React.MouseEvent<T>,
	selector: string,
	attrName: string
) => {
	const control = (event.target as T).closest(selector) as Q;
	if (!control) return undefined;
	return control.dataset[attrName];
};

/**
 * The function `getToday` returns the start of the current day using the dayjs library in TypeScript.
 */
export const getToday = () => dayjs().startOf('day');

/**
 * The function `getObjectFromSearchParams` takes URLSearchParams and default values, and returns an
 * object with the search parameters, ensuring that the values are of type string.
 * @param {URLSearchParams} searchParams - The `searchParams` parameter is of type `URLSearchParams`,
 * which represents a set of query parameters in a URL. It allows you to access and manipulate the
 * query parameters of a URL easily.
 * @param {T} defaults - The `defaults` parameter in the `getObjectFromSearchParams` function is an
 * object that defines the default values for the properties of the resulting object. These default
 * values will be used if the corresponding key is not found in the `searchParams` object.
 * @returns The function `getObjectFromSearchParams` returns an object with properties based on the
 * keys and values in the `searchParams` URLSearchParams object. If a key in `searchParams` matches a
 * key in the `defaults` object, the value from `searchParams` is assigned to that key in the returned
 * object. If a key in `searchParams` does not exist in `defaults`, it is
 */
export const getObjectFromSearchParams = <T extends { [key: string]: string }>(
	searchParams: URLSearchParams,
	defaults: Partial<T> = {}
) => {
	const params: any = { ...defaults };
	searchParams.forEach((value, key) => {
		// здесь value это строка!
		if (!value) return;
		params[key as keyof T] = value;
	});
	return params as T;
};

/**
 * The function `getObjectDifference` compares two objects of the same type and returns the difference
 * between them.
 * @param {T} model - The `model` parameter represents the original object with which you want to
 * compare another object to find the differences.
 * @param {T} object - The `object` parameter in the `getObjectDifference` function is an object of
 * type `T`, which is a generic type that extends `TObjectType`. This object is compared with another
 * object called `model`, and the function returns the difference between the two objects as a new
 * object of type `Partial
 * @returns The function `getObjectDifference` returns a partial object of type `T` which represents
 * the difference between the `model` object and the `object` object. The function iterates over the
 * keys in the `object` object and deletes any key-value pairs where the value in the `object` object
 * is equal to the value in the `model` object. The remaining key-value pairs in the `
 */
export const getObjectDifference = <T extends TObjectType>(model: T, object: T): Partial<T> => {
	for (const key in object) {
		if (object[key] === model[key]) {
			delete object[key];
		}
	}
	return object;
};

/**
 * The function `getAverageDate` calculates the average date from an array of date strings.
 * @param {string[]} dates - An array of string dates.
 */
export const getAverageDate = (dates: string[]) =>
	dates.length !== 0
		? new Date(
				dates.reduce((acc, hit_date) => {
					return acc + new Date(hit_date).getTime();
				}, 0) / dates.length
		  )
		: new Date();

export const getParamsForSearchInEntity = (searchParams: URLSearchParams): Partial<ISearchParams> => {
	const availableParams = new Set(Object.keys(DEFAULT_SEARCH_PARAMS));
	const result: Partial<ISearchParams> = {};
	for (const key of searchParams.keys()) {
		if (availableParams.has(key)) result[key] = searchParams.get(key);
	}
	if (result.period_index === DEFAULTS.userCustomPeriodIndex.toString()) {
		if (!result.start_date) result.start_date = currentYMD();
		if (!result.end_date) result.end_date = currentYMD();
	} else {
		const date = searchParams.get('date');
		if (date) {
			result.period_index = DEFAULTS.userCustomPeriodIndex.toString();
			result.start_date = result.end_date = date;
		}
	}
	return result;
};
