import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useRef } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import FetchError from '../../components/FetchError';
import { IUkrNetMessage } from '../../init';
import { moveControlToScreenCenter } from '../../scrolls';
import { useUkrnetSimilarMessagesQuery } from '../../services/messages';
import { MessageSmallTime } from '../../styledComponents';
import MessageSmall from '../messages/MessageSmall';

interface IUkrnetMessageProps {
	message: IUkrNetMessage;
	today: Dayjs;
	expanded: boolean;
}
const UkrnetMessage = ({ message, today, expanded }: IUkrnetMessageProps) => {
	const {
		data: messages,
		error,
		isFetching,
	} = useUkrnetSimilarMessagesQuery(expanded ? message.title : skipToken, {
		pollingInterval: 15 * 60 * 1000,
	});
	const ref = useRef(null);

	useEffect(() => {
		if (!messages) return;
		if (expanded) setTimeout(moveControlToScreenCenter, 100, ref.current);
	}, [messages, expanded]);

	const messageDate = dayjs(message.created);
	return (
		// <div className="list-group-item lh-sm" ref={ref}>
		<div className="position-relative" ref={ref}>
			<div className="d-flex align-items-start lh-sm">
				<MessageSmallTime className="text-nowrap text-body-tertiary flex-shrink-0" width={56}>
					{messageDate.format(messageDate.isSame(today, 'day') ? 'LT' : 'D MMM')}
				</MessageSmallTime>
				{/* <span className="text-nowrap text-body-tertiary">
				</span> */}
				<div>
					<button
						data-un-id={message.id}
						className="btn-un-msg btn-no-decor link-body-emphasis text-decoration-none message-title"
					>
						{message.title}
					</button>
					{expanded && <div className="text-secondary text-small">{message.description}</div>}
					{expanded && (
						<>
							{error && <FetchError error={error} />}
							{messages &&
								(messages.length !== 0 ? (
									<Alert variant="secondary" className="mt-2 mb-0 vstack gap-2">
										{messages.map((message) => (
											<MessageSmall key={message.id} message={message} className="position-relative" timeWidth={56} />
										))}
									</Alert>
								) : (
									<Alert variant="info" className="mt-2 mb-0 py-1">
										Нажаль, поки відсутня інформація по&nbsp;даній тематиці. Наразі ми&nbsp;прикладаємо максимум зусиль
										для&nbsp;її&nbsp;пошуку.
									</Alert>
								))}
						</>
					)}
				</div>
				{isFetching && (
					<Spinner animation="border" variant="secondary" size="sm" className="position-absolute top-0 end-0" />
				)}
			</div>
			{/* {expanded && (
				<>
					{error && <FetchError error={error} />}
					{messages &&
						(messages.length !== 0 ? (
							<ExpandedList variant="secondary" className="mt-2 mb-0 vstack gap-2 p-2">
								{messages.map((message) => (
									<MessageSmall key={message.id} message={message} className="position-relative" />
								))}
							</ExpandedList>
						) : (
							<div className="alert alert-info mt-2 mb-0 py-1">
								Нажаль, поки відсутня інформація по&nbsp;даній тематиці. Наразі ми&nbsp;прикладаємо максимум зусиль
								для&nbsp;її&nbsp;пошуку.
							</div>
						))}
				</>
			)} */}
		</div>
	);
};

export default UkrnetMessage;

// const ExpandedList = styled(Alert)`
// 	margin-left: -0.5rem;
// 	margin-right: -0.5rem;
// `;
