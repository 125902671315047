import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SORT_INDEX_HIT_DATE_DESC, getMessagesListParams } from '../../interfaces/params';
import { PREDEFINED_SOURCES, PREDEFINED_SOURCES_GOV, PREDEFINED_SOURCES_IA_INDEX } from '../../interfaces/sources';
import MessageList, { IMessageListRenderProps } from './MessageList';
import MessageSmall from './MessageSmall';

// interface INewsLineProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const NewsLine = () => {
	const sourceList = useMemo(
		() =>
			[
				...PREDEFINED_SOURCES[PREDEFINED_SOURCES_IA_INDEX].sources,
				...PREDEFINED_SOURCES[PREDEFINED_SOURCES_GOV].sources,
			].join(' '),
		[]
	);

	const queryString = useMemo(
		() => getMessagesListParams({ sourceList, countries: 'UA', rows: 50, withoutBody: true }),
		[sourceList]
	);

	const moreNewsLink = useMemo(
		() =>
			`/search?${new URLSearchParams({
				term: '*',
				sort_index: SORT_INDEX_HIT_DATE_DESC,
				sources: sourceList,
			}).toString()}`,
		[sourceList]
	);

	const renderMessage = useCallback(
		({ message }: IMessageListRenderProps) => (
			<MessageSmall key={message.id} message={message} className="list-group-item" />
		),
		[]
	);

	return (
		<div className="d-flex flex-column">
			<div className="text-center bg-body-secondary p-2 fs-4">
				<Link to={moreNewsLink} className="text-decoration-on-hover text-body text-condensed">
					Останні новини
				</Link>
			</div>
			<MessageList
				pageNo={0}
				queryString={queryString}
				renderChild={renderMessage}
				className="list-group list-group-flush"
				// pollingInterval={DEFAULTS.updateInterval * 1000}
				showLoading={false}
			/>
			<div className="text-center mb-2">
				<Link to={moreNewsLink} className="link-secondary">
					Більше новин
				</Link>
			</div>
		</div>
	);
};

export default NewsLine;
