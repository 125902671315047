import classNames from 'classnames';
import React from 'react';

interface ILinkButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	small?: boolean;
}
const LinkButton = ({ className, type, children, small = false, ...rest }: ILinkButtonProps) => {
	return (
		<button type={type} className={classNames('btn btn-link p-0 lh-1', small && 'btn-sm', className)} {...rest}>
			{children}
		</button>
	);
};

export default LinkButton;
