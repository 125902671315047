import dayjs from 'dayjs';
import { CSSObject } from 'styled-components';
import { TFacetValue } from './init';
import { IDictionary } from './interfaces/common';
import { ICountryDic, ISourceDic } from './interfaces/dict';
import { IStatDateData, IStatPieData } from './interfaces/statistic';

export const commonChartStyle: CSSObject = {
	fontFamily: 'Roboto, "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
};
export const commonContainerProps = {
	className: 'w-100 flex-grow-1',
	style: {
		height: '75vh',
	},
};
export const STAT_Y_TITLE = 'Кількість повідомлень';

export const getStatDateData = (source: TFacetValue[]) => {
	const result: IStatDateData[] = [];
	for (let i = 0; i < source.length / 2; i++) {
		result.push({
			date: source[i * 2] as string,
			count: source[i * 2 + 1] as number,
		});
	}
	return result;
};

export const getStatDateGap = (startDate: string | undefined, endDate: string | undefined) => {
	if (!startDate || !endDate) return '+1MONTH';
	const difference = dayjs.utc(endDate).diff(startDate, 'days');

	if (difference === 0) return '+1HOUR';
	if (difference <= 45) return '+1DAY';
	if (difference <= 186) return '+7DAY';
	if (difference < 750) return '+1MONTH';
	return '+1YEAR';
};

export const getCountryTitle = (() => {
	const cache: { [key: string]: string } = {};

	return (_country: string, countries: IDictionary<ICountryDic> | undefined) => {
		const country = _country.toUpperCase();
		if (!countries) return country;
		if (!cache[country]) {
			cache[country] = countries[country]?.title || `Невідома країна (id=${country})`;
		}
		return cache[country];
	};
})();

export const getSourceLabelInner = (() => {
	const cache: { [key: number]: string } = {};

	return (id: number, sources: IDictionary<ISourceDic>) => {
		if (cache[id] === undefined) {
			// Это будет срабатывать только в начале, если не загружена информация об источниках
			if (Object.keys(sources).length === 0) return id;

			const source: ISourceDic | undefined = sources[id];
			const country = source?.country.toLowerCase() || 'un';
			const title = source?.title || `Невідоме джерело (id=${id})`;
			cache[
				id
			] = `<i class="flex-shrink-0 shadow-country fi fi-${country}"></i><span class="pie-label__text text-truncate">${title}</span>`;
			// return `<i class="flex-shrink-0 shadow-country fi fi-${country}"></i><span class="pie-label__text text-truncate">${title}</span><div class="pie-label__img-source rounded flex-shrink-0" style="background-image: url(/execute/Images/sources/${getSourceImg(
			// 	id
			// )}), url(${DEFAULTS.srcImg})"></div>`;
		}
		return cache[id];
	};
})();

export const getStatNamedPieData = (data: TFacetValue[]) => {
	const result: IStatPieData[] = [];
	for (let i = 0; i < data.length / 2; i++) {
		result.push({
			name: data[i * 2] as string,
			y: data[i * 2 + 1] as number,
		});
	}

	return result;
};
