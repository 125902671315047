import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { BsMoonStars, BsSun } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { changeColorTheme } from '../features/app/appSlice';
import { RootState } from '../store';
import TextIcon from './textIcon';

interface IThemeButtonProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const ThemeButton = ({ colorTheme, changeColorTheme }: IThemeButtonProps) => {
	const onThemeButtonClick = () => {
		changeColorTheme(isLightTheme ? 'dark' : 'light');
	};

	const isLightTheme = colorTheme === 'light';
	return (
		<NavDropdown.Item as="button" onClick={onThemeButtonClick}>
			<TextIcon Icon={isLightTheme ? BsMoonStars : BsSun}>{isLightTheme ? 'Темна тема' : 'Світла тема'}</TextIcon>
		</NavDropdown.Item>
	);
};

const mapState = (state: RootState) => ({
	colorTheme: state.app.colorTheme,
});

const mapDispatch = { changeColorTheme };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ThemeButton);
