import { useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ConnectedProps, connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import PageTitle from '../../components/PageTitle';
import WithSources, { IWithSources } from '../../components/hoc/withSources';
import UpdateButton from '../../components/updateButton';
import { getMessagesListParams } from '../../interfaces/params';
import { RootState } from '../../store';
import MessageList, { IMessageListRenderProps } from '../messages/MessageList';
import MessageMedium from '../messages/MessageMedium';
import NewsLine from '../messages/NewsLine';
import { changeTs, clearSearchState } from '../searcher/searcherSlice';
import UkrnetLayout from '../ukrnet/UkrnetLayout';

interface IMainLayoutProps extends PropsFromRedux, IWithSources, React.AllHTMLAttributes<HTMLDivElement> {}
const MainLayout = ({ sourcesTop, sourcesById, pageNo, clearSearchState, changeTs }: IMainLayoutProps) => {
	const topQueryString = useMemo(
		() =>
			sourcesTop.length !== 0
				? getMessagesListParams({ sourceList: sourcesTop.join(' '), startDate: 'NOW/DAY', len: 250 })
				: '',
		[sourcesTop]
	);

	useEffect(() => {
		return () => {
			clearSearchState();
		};
	}, [clearSearchState]);

	const renderMessage = useCallback(
		({ message }: IMessageListRenderProps) => (
			<MessageMedium key={message.id} message={message} source={sourcesById[message.source_id]} />
		),
		[sourcesById]
	);

	const onUpdate = useCallback(() => {
		changeTs();
	}, [changeTs]);

	return (
		<>
			<PageTitle />
			<Container className="mt-2" fluid="xxl">
				{/* {pageNo === 0 && <UkrnetLayout />} */}
				<Row>
					<Col xs={12} md={5} lg={4} className="order-1">
						<NewsLine />
					</Col>
					<Col xs={12} md={7} lg={8}>
						{pageNo === 0 && <UkrnetLayout />}
						<div className="text-center bg-body-secondary p-2">
							<div className="fs-4 text-condensed">
								Стрічка публікацій<span className="d-none d-lg-inline"> Топ&ndash;30 джерел</span>
							</div>
							{/* <RefreshButton
								className="position-fixed w-auto top-adapted end-0 me-2"
								onUpdate={onUpdate}
								title="Оновити інформацію"
								adaptive
							/> */}
						</div>
						<MessageList
							pageNo={pageNo}
							queryString={topQueryString}
							renderChild={renderMessage}
							className="top-30__container gap-2rem"
							// pollingInterval={DEFAULTS.updateInterval * 1000}
							showLoading={false}
							mutateState
						/>
					</Col>
				</Row>
				<RefreshButton className="position-fixed w-auto z-1" onUpdate={onUpdate} title="Оновити інформацію" adaptive />
			</Container>
			<Outlet />
		</>
	);
};

const mapState = (state: RootState) => ({
	pageNo: state.searcher.pageNo,
});

const mapDispatch = {
	clearSearchState,
	changeTs,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithSources(MainLayout));

const RefreshButton = styled(UpdateButton)`
	top: 74px;
	right: 1.5rem;
`;
