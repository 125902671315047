import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionMeta, SingleValue } from 'react-select';
import { DEFAULTS, permissionExists, storage, TColorTheme, TStringWithUndefined, updateThemeInPage } from '../../init';
import { ISelectOption } from '../../interfaces/common';
import { DEFAULT_SEARCH_PARAMS, ISearchParams } from '../../interfaces/params';
import { AppDispatch } from '../../store';

export interface IAppSlice {
	/**
	 * Цей параметр містить оперативні параметри, які змінює користувач в процесі налаштувань.
	 * Якщо Вам потрібні параметри, які використовувались для останнього пошуку,
	 * то потрібно брати ті, що повертаються за допомогою useSearchParams
	 */
	searchParams: ISearchParams;
	sources: number[];
	userPermissions: string[];
	hasAccess?: boolean;
	isAdmin?: boolean;
	accessToken: TStringWithUndefined;
	colorTheme: TColorTheme;
	pageIsVisible: boolean;
}
const initialState: IAppSlice = {
	searchParams: {
		...DEFAULT_SEARCH_PARAMS,
	},
	sources: [],
	userPermissions: [],
	accessToken: undefined,
	colorTheme: (storage.get('theme') as TColorTheme) || 'light',
	pageIsVisible: true,
};
export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		changeAccessToken: (state, action: PayloadAction<TStringWithUndefined>) => {
			state.accessToken = action.payload;
		},
		changeParams: (state, action: PayloadAction<Partial<ISearchParams>>) => {
			const { sources: oldSources } = state.searchParams;
			const newSources = action.payload.sources;
			state.searchParams = {
				...state.searchParams,
				...action.payload,
			};
			if (newSources !== undefined && newSources !== oldSources) state.sources = getSourcesFrowRaw(newSources || '');
		},
		clearParamsWithoutTerm: (state) => {
			state.searchParams = {
				...DEFAULT_SEARCH_PARAMS,
				term: state.searchParams.term,
			};
			state.sources = [];
		},
		changeSources: (state, action: PayloadAction<number[]>) => {
			state.sources = [...action.payload];
			state.searchParams.sources = getRawFromSources(action.payload);
		},
		addSource: (state, action: PayloadAction<number>) => {
			const newSourceId = action.payload;
			if (state.sources.includes(newSourceId)) return;
			state.sources = [newSourceId, ...state.sources];
			state.searchParams.sources = getRawFromSources(state.sources);
			// const newSources = [newSourceId, ...state.sources];
			// state.sources = newSources;
			// state.searchParams.sources = getRawFromSources(newSources);
		},
		removeSource: (state, action: PayloadAction<number>) => {
			const oldSourceId = action.payload;
			if (!state.sources.includes(oldSourceId)) return;
			state.sources = state.sources.filter((sourceId) => sourceId !== oldSourceId);
			state.searchParams.sources = getRawFromSources(state.sources);
			// const newSources = state.sources.filter((sourceId) => sourceId !== oldSourceId);
			// state.sources = newSources;
			// state.searchParams.sources = getRawFromSources(newSources);
		},
		changePermissions: (state, action: PayloadAction<string[] | undefined>) => {
			state.userPermissions = action.payload ? [...action.payload] : [];
			state.hasAccess = action.payload ? permissionExists(DEFAULTS.accessPermissionScope, action.payload) : undefined;
			state.isAdmin = action.payload ? permissionExists(DEFAULTS.adminPermissionScope, action.payload) : undefined;
		},
		changeColorTheme: (state, action: PayloadAction<TColorTheme>) => {
			state.colorTheme = action.payload;
			storage.set({ theme: action.payload });
			updateThemeInPage(action.payload);
		},
		changePageIsVisible: (state, action: PayloadAction<boolean>) => {
			state.pageIsVisible = action.payload;
		},
	},
});

export const {
	changeAccessToken,
	changeParams,
	changeSources,
	addSource,
	removeSource,
	changePermissions,
	changeColorTheme,
	changePageIsVisible,
	clearParamsWithoutTerm,
} = appSlice.actions;

export const onChangeSearchParam =
	(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => (dispatch: AppDispatch) => {
		dispatch(changeParams({ [event.target.name]: event.target.value }));
	};

export const onSelectChangeSearchParam =
	(value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => (dispatch: AppDispatch) => {
		const name = actionMeta.name as keyof ISearchParams;
		dispatch(changeParams({ [name]: value?.value || DEFAULT_SEARCH_PARAMS[name] }));
	};

export default appSlice.reducer;

const getSourcesFrowRaw = (sources: string) => [...new Set(sources.split(/\s+/).map(Number).filter(Boolean))];

const getRawFromSources = (sources: number[]) => sources.join(' ');
