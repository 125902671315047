import { useEffect } from 'react';
import styled from 'styled-components';
import logoImg from '../img/search-app.webp';
import LoginButton from './LoginButton';

const SplashScreen = () => {
	useEffect(() => {
		const body = document.querySelector('body');
		if (!body) return;

		body.classList.add('not-auth');

		return () => body.classList.remove('not-auth');
	}, []);

	return (
		<SplashContainer className="d-flex flex-column align-items-center m-auto">
			<LogoImage src={logoImg} />
			<LoginButton large className="z-index-1" />
		</SplashContainer>
	);
};

export default SplashScreen;

const SplashContainer = styled.div`
	padding-bottom: 120px;
`;
const LogoImage = styled.img`
	height: 38vmin;
	width: auto;
	filter: drop-shadow(0 0 5px white);
`;
