// Entities

export type TEntityId<T extends number | string> = T;

export interface IDictionaryNum<T> {
	[id: number]: T | undefined;
}

export interface IDictionary<T> extends IDictionaryNum<T> {
	[id: string]: T | undefined;
}

export interface IEntityState<T, Q extends number | string> {
	ids: TEntityId<Q>[];
	entities: IDictionary<T>;
}

// Entity Mutation

export type TEntityEditMethod = 'POST' | 'PUT' | 'DELETE';
export interface IEntityEditMethod {
	method: TEntityEditMethod;
}

// Other

export interface IErrorResponse {
	error: string;
}

export type TObjectType = { [key: string]: any };

export interface ISelectOption {
	label: string;
	value: string;
}

export const QUERY_TAGS = {
	rubric: 'RUBRIC',
	source: 'SOURCE',
};

export type TMutatingRow<T, F extends keyof T> = Partial<T> & Pick<T, F>;
