import React, { useCallback, useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useMatch, useMatches, useNavigate, useSearchParams } from 'react-router-dom';
import { showError } from '../alerts';
import { changeParams } from '../features/app/appSlice';
import { changeQueryString } from '../features/searcher/searcherSlice';
import { DEFAULTS, periodIsValid } from '../init';
import { DEFAULT_SEARCH_PARAMS, ISearchParams } from '../interfaces/params';
import { RootState } from '../store';
import { getParamsForSearchInEntity } from '../utils';
import SearchForm from './SearchForm';

interface ISearchInEntityOptions {
	type: 'S' | 'R';
	id: string;
}

interface IMessagesSearchFormProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const MessagesSearchForm = ({ params, changeParams, className, changeQueryString }: IMessagesSearchFormProps) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const location = useLocation();
	const inSearchRoute = useMatch('/search/*');
	const matches = useMatches();
	const [searchInEntityOptions, setSearchInEntityOptions] = useState<ISearchInEntityOptions | null>(null);

	useEffect(() => {
		if (matches.length === 0) return setSearchInEntityOptions(null);
		const { sourceId, rubricId } = matches[0].params;
		if (!(sourceId || rubricId)) return setSearchInEntityOptions(null);
		const type = sourceId ? 'S' : 'R';
		const id = sourceId || rubricId || '0';
		if (searchInEntityOptions?.type !== type || searchInEntityOptions.id !== id) setSearchInEntityOptions({ type, id });
	}, [matches]); // eslint-disable-line

	const onSearchFormSubmit = (term: string, searchInActiveEntity?: boolean) => {
		const newSearchParams: Partial<ISearchParams> = {};
		if (inSearchRoute)
			Object.keys(params).forEach((searchKey) => {
				const key = searchKey as keyof ISearchParams;
				if (params[key] === DEFAULT_SEARCH_PARAMS[key]) return;
				newSearchParams[key] = params[key];
			});
		newSearchParams.term = term;

		/**
		 * Якщо користувач обрав пошук серед повідомлень джерела,
		 * то додаємо дане джерело в параметри.
		 * В іншому разі залишаємо список джерел лише у тому випадку,
		 * якщо ми знаходимось на сторінці пошуку, інакше очищуємо список джерел.
		 */
		if (searchInEntityOptions && searchInActiveEntity) {
			if (searchInEntityOptions.type === 'S') newSearchParams.sources = searchInEntityOptions.id;
			else newSearchParams.rubric = searchInEntityOptions.id;
			Object.assign(newSearchParams, getParamsForSearchInEntity(searchParams));
		} else {
			if (!inSearchRoute) {
				delete newSearchParams.sources;
				delete newSearchParams.rubric;
			}
		}

		if (
			inSearchRoute &&
			parseInt(params.period_index) === DEFAULTS.userCustomPeriodIndex &&
			!periodIsValid(params.start_date, params.end_date)
		) {
			showError('Кінцева дата має бути не менша за початкову.');
			return;
		}

		let path = location.pathname;
		if (!(path.startsWith('/search') || path.startsWith('/statistic'))) path = '/search';

		if (new URLSearchParams(newSearchParams).toString() !== searchParams.toString())
			navigate(`${path}?${new URLSearchParams(newSearchParams).toString()}`);
		// К сожалению, не могу пока придумать, как запустить поиск при одинаковых параметрах
		// (когда пользователь ничего не меняет, а просто нажимает кнопку поиска).
		// Поэтому пока инициирую поиск здесь.
		else changeQueryString({ params, updateTs: true });
	};

	const onTermChange = useCallback(
		(term: string) => {
			if (term !== params.term) changeParams({ term });
		},
		[changeParams, params.term]
	);

	return (
		<SearchForm
			id="fmMessageSearch"
			initTerm={params.term}
			className={className}
			onTermSubmit={onSearchFormSubmit}
			onTermChange={onTermChange}
			checkboxTitle={
				searchInEntityOptions ? (searchInEntityOptions.type === 'S' ? 'пошук в джерелі' : 'пошук в рубриці') : undefined
			}
		>
			{/* <UserQueryMenu onTermChange={onSearchFormSubmit} term={params.term} /> */}
		</SearchForm>
	);
};

const mapState = (state: RootState) => ({
	params: state.app.searchParams,
});

const mapDispatch = {
	changeQueryString,
	changeParams,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MessagesSearchForm);
