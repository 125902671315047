import {
	ICalendarData,
	IMessage,
	ISearchResponse,
	IUkrNetMessage,
	IUkrnetSectionItem,
	IVideo,
	TStringWithUndefined,
} from '../init';
import { searcherApi } from './searcherApi';

interface IMessagesFacetForMonthParams {
	df: string;
	query: string;
	start: string;
	sources?: string;
	TZ: string;
	country?: string | null;
}

export interface IMessagesFacetForMonth extends IMessagesFacetForMonthParams {
	count: number;
	facets: ICalendarData[];
}

export interface IVideoSearchParams {
	TZ: string;
	term: string;
	severity: string;
	sort: string;
	period?: string;
	rubric_id?: string;
}

const sourcesApi = searcherApi.injectEndpoints({
	endpoints: (builder) => ({
		messageStatistic: builder.query<ISearchResponse, string>({
			query: (params) => ({
				url: 'search',
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: params,
				// body: getObjectFromSearchParams(new URLSearchParams(params)),
			}),
		}),
		messageById: builder.query<IMessage, { id: TStringWithUndefined; term: string }>({
			// query: ({ id, term }) => `post/${id}?term=${encodeURIComponent(term)}`,
			query: ({ id, term }) => ({
				url: 'post/by/id',
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: `id=${id}&term=${encodeURIComponent(term)}`,
			}),
		}),
		getMessages: builder.query<ISearchResponse, { queryString: string; pageNo: number; ts: number }>({
			query: ({ queryString, pageNo }) => ({
				url: 'search',
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: `${queryString}&page=${pageNo}`,
				// body: getObjectFromSearchParams(new URLSearchParams(`${queryString}&page=${pageNo}`)),
			}),
		}),
		getVideos: builder.query<IVideo[], IVideoSearchParams>({
			query: (params) => ({
				url: 'search/videos',
				method: 'POST',
				body: params,
			}),
		}),
		// getVideos: builder.query<IVideo[], string>({
		// 	query: (queryString) => `search/videos?${queryString}`,
		// }),
		messagesFacetsForMonth: builder.query<IMessagesFacetForMonth, IMessagesFacetForMonthParams>({
			query: (params) => ({
				url: 'posts/facetsForMonth',
				method: 'POST',
				body: { ...params },
				// body: { ...params, TZ: USER_GMT },
				/**
				 * Цей заголовок потрібну було б використовувати, якщо б параметри передавались
				 * за допомогою строки. В цьому разі на сервері необхідно було б
				 * використовувати middleware express.urlencoded().
				 * Але ми будем використовувати json 😃
				 */
				// body: 'params=' + encodeURIComponent(JSON.stringify({ ...params, TZ: USER_GMT })),
				// headers: {
				// 	'Content-Type': 'application/x-www-form-urlencoded',
				// },
			}),
		}),
		ukrnetSections: builder.query<IUkrnetSectionItem[], void>({
			query: () => 'un',
		}),
		ukrnetSectionMessages: builder.query<IUkrNetMessage[], { section: string; count?: number }>({
			query: ({ section, count }) => `un/${section}${count ? `?count=${count}` : ''}`,
		}),
		ukrnetSimilarMessages: builder.query<IMessage[], string>({
			query: (title) => `search/un?title=${encodeURIComponent(title.replace(/[:|&~]+/gi, ''))}`,
		}),
	}),
});

export const {
	useMessageStatisticQuery,
	useMessageByIdQuery,
	useGetMessagesQuery,
	useGetVideosQuery,
	useMessagesFacetsForMonthQuery,
	useUkrnetSectionsQuery,
	useUkrnetSectionMessagesQuery,
	useUkrnetSimilarMessagesQuery,
} = sourcesApi;
