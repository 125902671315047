import { IEntityState, TMutatingRow } from './common';
import { ISourceDic } from './dict';

export interface ISourceDicBase {
	ID: number;
	TITLE: string;
	COUNTRY: string;
	DESCRIPTION?: string | null;
}

export interface ISourceKeyStat {
	id: number;
	count?: number;
	max_date?: string;
}

export interface ISourceForList extends ISourceKeyStat, ISourceDic {}
export type TSourceByLetter = IEntityState<ISourceForList[], string>;

interface IPredefinedSource {
	label: string;
	sources: number[];
}
export const PREDEFINED_SOURCES: IPredefinedSource[] = [
	{
		label: 'Органи державної влади',
		sources: [59, 60, 65, 66, 314, 299, 492, 18, 571, 172, 600, 514, 570, 863],
	},
	{
		label: 'Інформаційні агентства',
		sources: [90, 296, 302, 320, 84, 594, 192, 540, 276, 415, 78, 346, 195, 298, 420, 414, 317, 507, 1, 369],
	},
	{
		label: 'Соціальні мережі, блоги',
		sources: [573, 458, 435, 498, 181, 417],
	},
];
export const PREDEFINED_SOURCES_IA_INDEX = 1;
export const PREDEFINED_SOURCES_GOV = 0;
export const PREDEFINED_SOURCES_SM = 2;

export const SOURCE_DEFAULT: ISourceDicBase = {
	ID: 0,
	TITLE: '',
	COUNTRY: 'UA',
	DESCRIPTION: null,
};

export type TMutatingSource = TMutatingRow<ISourceDicBase, 'ID'>;
