import dayjs from 'dayjs';
import React from 'react';

interface IHumanReadableTimeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isoDate: string;
	showRealTime?: boolean;
}
const HumanReadableTime = ({ isoDate, showRealTime = false }: IHumanReadableTimeProps) => {
	const published = dayjs(isoDate);
	return (
		<span title={published.format('L LT')}>
			<span className="fw-500">{published.fromNow()}</span>
			{showRealTime && (
				<span className="text-muted ms-1">
					({published.format(published.isSame(dayjs(), 'date') ? '[Сьогодні о ]LT' : 'L LT')})
				</span>
			)}
		</span>
	);
};

export default HumanReadableTime;
