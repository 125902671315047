import { IconType } from 'react-icons';
import { BsBarChartLine, BsGlobe, BsNewspaper } from 'react-icons/bs';

export type TChartDataForDownload = [string, number][] | null;
export type TUpdateChartData = (data: TChartDataForDownload) => void;

export interface IStatDateData {
	date: string;
	count: number;
}

export interface IStatPieData {
	name: string;
	y: number;
}

export type TStatisticTabs = 'dynamic' | 'countries' | 'sources';
export type TStatisticFields = 'hit_date' | 'country' | 'source_id';
export interface IStatisticTab {
	route: TStatisticTabs;
	title: string;
	Icon: IconType;
	field: TStatisticFields;
	isRange?: boolean;
}
export const STATISTIC_TABS: IStatisticTab[] = [
	{
		route: 'dynamic',
		title: 'Динаміка',
		Icon: BsBarChartLine,
		field: 'hit_date',
		isRange: true,
	},
	{
		route: 'countries',
		title: 'Країни',
		Icon: BsGlobe,
		field: 'country',
	},
	{
		route: 'sources',
		title: 'Джерела',
		Icon: BsNewspaper,
		field: 'source_id',
	},
];
