import React from 'react';
import { BsMicMute } from 'react-icons/bs';
import styled from 'styled-components';
import HumanReadableTime from '../../components/HumanReadableTime';
import TextIcon from '../../components/textIcon';
import { IVideo, getHumanDigitalDuration } from '../../init';

interface IVideoItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	video: IVideo;
	tvParams: string;
	// channel?: IChannel;
	// active: boolean;
	// no: number;
}
const VideoItem = ({ video, tvParams }: IVideoItemProps) => {
	const {
		TITLE,
		LOGO,
		YOUTUBE_ID,
		PUBLISHED_AT,
		DURATION,
		hl,
		real_duration,
		channelLogo,
		channelTitle,
		// channelYoutubeId,
	} = video;

	const duration = DURATION || real_duration || 0;
	return (
		<div className="position-relative hstack flex-row gap-3 align-items-start pb-3 border-bottom">
			<VideoImageContainer>
				<VideoImage src={LOGO} className="rounded w-100">
					<ChannelImage
						src={channelLogo}
						alt={channelTitle}
						className="rounded-circle position-absolute top-0 end-0 mt-2 me-2 white-halo"
					/>
					<div className="position-absolute bottom-0 end-0 mb-2 me-2">
						{duration !== 0 ? (
							<VideoDuration className="badge white-halo" title="Тривалість">
								{getHumanDigitalDuration(duration)}
							</VideoDuration>
						) : (
							<OnAirIcon Icon={BsMicMute} className="text-warning" title="Відео скоріше за все не містить тексту" />
						)}
					</div>
				</VideoImage>
			</VideoImageContainer>
			<div className="d-flex flex-column gap-1 min-width-0 mt-n1">
				<a
					target="_blank"
					rel="noreferrer"
					href={`https://tv.${process.env.REACT_APP_DOMAIN_NAME}/#/video/${YOUTUBE_ID}?${tvParams}`}
					className="text-decoration-none stretched-link h5 text-condensed link-primary m-0"
				>
					{TITLE}
				</a>
				<span className="text-truncate">
					<span>{channelTitle}</span> • <HumanReadableTime isoDate={PUBLISHED_AT} showRealTime />
				</span>
				{hl && hl.length !== 0 && (
					<VideoText className="highlighting-container">
						{hl.map((text, index) => (
							<p key={index} dangerouslySetInnerHTML={{ __html: `&hellip;${text}&hellip;` }} />
						))}
					</VideoText>
				)}
			</div>
		</div>
	);
};

export default VideoItem;

const OnAirIcon = styled(TextIcon)`
	font-size: 1.5rem;
	filter: drop-shadow(0 -1px 1px black);
`;

const ChannelImage = styled.img`
	width: 1.5em;
	height: 1.5em;
`;

const VideoText = styled.div`
	p {
		margin-bottom: 0.25em;
		overflow-wrap: anywhere;
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const VideoImageContainer = styled.div`
	flex: 0 0 30%;
`;

interface IVideoImageProps {
	src: string;
}
const VideoImage = styled.div<IVideoImageProps>`
	position: relative;
	padding-top: 56%; /* Player ratio: 100 / (1280 / 720) */
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: ${({ src }) => `rgba(var(--bs-secondary-bg-rgb), 1) url(${src})`};
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
`;

const VideoDuration = styled.span`
	background-color: rgba(0, 0, 0, 0.5);
	font-size: 0.8rem;
`;
