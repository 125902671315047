import styled from 'styled-components';

interface IPreloaderProps {
	fixed?: boolean;
}
const Preloader = ({ fixed = false }: IPreloaderProps) => (
	<PreloaderContainer className={fixed ? 'screen-center' : undefined}>
		<PreloaderSquare></PreloaderSquare>
		<PreloaderSquare></PreloaderSquare>
		<PreloaderSquare></PreloaderSquare>
	</PreloaderContainer>
);

export default Preloader;

const PreloaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 25vh;
`;
const PreloaderSquare = styled.div`
	width: 50px;
	height: 50px;
	background-color: var(--bs-gray-100);
	margin: 0 4px;
	animation: preloaderAnimation 1.2s infinite ease-in-out;
	&:nth-child(2) {
		animation-delay: 0.2s;
	}

	&:nth-child(3) {
		animation-delay: 0.4s;
	}
`;
