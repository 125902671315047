import React from 'react';
import { IDictionary, TEntityId } from '../../interfaces/common';
import { ICountryDic } from '../../interfaces/dict';
import { useDictionariesQuery } from '../../services/searcherApi';

export interface IWithCountries {
	countriesAllIds: TEntityId<string>[];
	countriesById: IDictionary<ICountryDic>;
}
const WithCountries =
	<T extends IWithCountries>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithCountries>) => {
		// eslint-disable-next-line
		const { countries } = useDictionariesQuery(undefined, {
			selectFromResult: ({ data }) => ({ countries: data?.countries }),
			refetchOnMountOrArgChange: false,
		});

		return (
			<WrappedComponent
				{...(props as T)}
				countriesAllIds={countries?.ids || []}
				countriesById={countries?.entities || {}}
			/>
		);
	};

export default WithCountries;
