import dayjs, { Dayjs } from 'dayjs';
import InlineWaiter from '../../components/InlineWaiter';
import { ICalendarData } from '../../init';
import CalendarGrig from './CalendarGrid';
import MonthYearSelector from './MonthYearSelector';

interface ICalendarProps {
	selectedDate: Dayjs; // Тут встановлено час 12:00
	data?: ICalendarData[];
	searching?: boolean;
	// onDateClick?: (newDateYMD: string) => void;
	linkState?: any;
	TZ?: string;
}
const Calendar = ({ selectedDate, data, searching, linkState, TZ }: ICalendarProps) => {
	// const onDayClick = (newDate: Partial<IDate>) => {
	// 	if (!onDateClick) return;
	// 	const ymd = { year: selectedDate.year(), month: selectedDate.month() + 1, day: 1, ...newDate };
	// 	onDateClick(`${ymd.year}-${ymd.month.toString().padStart(2, '0')}-${ymd.day.toString().padStart(2, '0')}`);
	// };

	return (
		<MonthYearSelector selectedDate={selectedDate} className="position-relative" linkState={linkState}>
			<CalendarGrig
				data={data}
				// onDayClick={onDayClick}
				firstDoW={selectedDate.startOf('month').day() || 7}
				selectedDate={selectedDate}
				linkState={linkState}
				emptyGrid={!(data && data.length !== 0 && selectedDate.isSame(dayjs(data[0].date).add(12, 'hours'), 'month'))}
				TZ={TZ}
			/>
			{searching && <InlineWaiter>Завантаження даних для календаря</InlineWaiter>}
		</MonthYearSelector>
	);
};

export default Calendar;
