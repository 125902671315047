import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface IMessageTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	text?: string;
}
const MessageText = ({ text }: IMessageTextProps) => {
	return text ? (
		<div className="msg__text">
			{text.split(/[\r\n]+/).map((line, index) => (
				<p key={index} dangerouslySetInnerHTML={{ __html: line }} />
				// <p key={index}>{prepareMessageText(line)}</p>
			))}
		</div>
	) : (
		<>
			{new Array(Math.round(Math.random() * 3) + 2).fill(undefined).map((dummy, index) => (
				<Skeleton key={index} count={Math.random() * 5} containerClassName="mb-2" />
			))}
		</>
	);
};

export default MessageText;
