import classNames from 'classnames';
import React from 'react';
import { selectAppStore, useAppSelector } from '../store';
import DotSpinner from './DotSpinner';

interface IInlineWaiterProps extends React.AllHTMLAttributes<HTMLDivElement> {
	dark?: boolean;
}
const InlineWaiter = ({ className, dark, children }: IInlineWaiterProps) => {
	const { colorTheme } = useAppSelector(selectAppStore);

	return (
		<DotSpinner
			className={classNames(
				'position-absolute center-with-transform p-2 text-nowrap rounded shadow-sm',
				dark ?? colorTheme === 'dark' ? 'dark-glass' : 'white-glass',
				className
			)}
		>
			{children}
		</DotSpinner>
	);
};

export default InlineWaiter;
