import { skipToken } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsArrowRight, BsFilm } from 'react-icons/bs';
import TextIcon from '../../components/textIcon';
import { USER_GMT, prepareSearchTerm } from '../../init';
import { DEFAULT_SEARCH_PARAMS, ISearchParams, PERIOD_OPTIONS, SORT_OPTIONS } from '../../interfaces/params';
import { IVideoSearchParams, useGetVideosQuery } from '../../services/messages';
import { getObjectFromSearchParams } from '../../utils';
import { getPeriodDates } from '../searcher/searcherSlice';
import VideoItem from './VideoItem';

interface IVideoListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	searchParams: URLSearchParams;
	/**
	 * Чи виводиться інформація про порядок сортування сюжетів:
	 * - S - за релевантністю;
	 * - D - за датою виходу сюжету зворотному порядку;
	 * - в іншому випадку інформація про сортування сюжетів не виводиться
	 */
	orderBy?: 'S' | 'D';
	polling?: boolean;
	/**
	 * Середня дата масиву повідомлень. При заданні цього параметру компонент адаптує позицію
	 * сюжетів, що виводяться: якщо час першого сюжету менше за цю дату, то список сюжетів
	 * виводиться перед списком повідомлень. В іншому випадку сюжети виводяться за повідомленнями.
	 */
	medianMessagesDate?: Date | null;
}
const VideoList = ({ searchParams, className, orderBy, polling = false, medianMessagesDate }: IVideoListProps) => {
	const [queryParams, setQueryParams] = useState<IVideoSearchParams>();
	const [isFirstChild, setIsFirstChild] = useState(medianMessagesDate !== null);
	const [tvParams, setTvParams] = useState('');
	const {
		data: videos,
		// isFetching,
		// isLoading,
		// isUninitialized,
		// error,
		// refetch,
	} = useGetVideosQuery(queryParams || skipToken, { pollingInterval: polling ? 10 * 60 * 1000 : undefined });

	useEffect(() => {
		const queryParams = getTranslatedParams(getObjectFromSearchParams(searchParams, DEFAULT_SEARCH_PARAMS));
		setQueryParams(queryParams);

		const availableParams = ['severity', 'period_index', 'sort_index', 'term', 'start_date', 'end_date'];
		const params = new URLSearchParams();
		for (const [key, value] of searchParams) {
			if (!availableParams.includes(key)) continue;
			params.append(key, value);
		}
		setTvParams(params.toString());
	}, [searchParams]);

	useEffect(() => {
		if (!videos?.length || medianMessagesDate === undefined) return;
		if (medianMessagesDate === null) return setIsFirstChild(false);
		setIsFirstChild(medianMessagesDate.getTime() < new Date(videos[0].PUBLISHED_AT).getTime());
	}, [videos, medianMessagesDate]);

	if (!videos?.length) return null;

	return (
		<Row className={classNames(isFirstChild ? 'order-0' : 'order-2', className)}>
			<Col xs={12} className="text-legend mb-1 text-truncate lh-1 mb-2">
				<BsFilm className="mt-n1" /> Відео, які пов'язано з пошуковим запитом
				{orderBy && (
					<span className={classNames('ms-1 small-text', orderBy === 'S' ? 'text-warning' : 'text-secondary')}>
						({orderBy === 'S' ? 'за релевантністю' : 'за датою публікації'})
					</span>
				)}
			</Col>
			<Col xs={12}>
				<div className="vstack gap-3">
					{videos.map((video, index) => (
						<VideoItem key={video.YOUTUBE_ID} video={video} tvParams={tvParams} />
					))}
				</div>
			</Col>
			<Col xs={12} className="text-center z-1">
				<a
					className="btn btn-secondary rounded-pill translate-middle-y"
					target="_blank"
					rel="noreferrer"
					href={`https://tv.${process.env.REACT_APP_DOMAIN_NAME}/#/video?${tvParams}`}
				>
					<TextIcon Icon={BsArrowRight} iconLast>
						Переглянути все
					</TextIcon>
				</a>
			</Col>
		</Row>
	);
};

export default VideoList;

const getTranslatedParams = ({
	term,
	severity,
	period_index,
	start_date,
	end_date,
	sort_index,
	rubric,
}: ISearchParams) => {
	const searchTerm = prepareSearchTerm(term) || '*';

	let sortIndex = parseInt(sort_index, 10);
	if (SORT_OPTIONS.length <= sortIndex) sortIndex = parseInt(DEFAULT_SEARCH_PARAMS.sort_index);

	let periodIndex = parseInt(period_index, 10);
	if (PERIOD_OPTIONS.length <= periodIndex) periodIndex = parseInt(DEFAULT_SEARCH_PARAMS.period_index);

	const translatedParams: IVideoSearchParams = {
		TZ: USER_GMT,
		term: searchTerm,
		severity: `${severity}%`,
		sort: SORT_OPTIONS[sortIndex].value as string,
	};
	if (period_index !== '0')
		translatedParams.period = `[${getPeriodDates(periodIndex, start_date, end_date).join(' TO ')}]`;
	if (rubric) translatedParams.rubric_id = rubric;
	return translatedParams;
};
