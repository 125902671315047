import { promiseTimeOut } from './init';

/**
 * Прокручує сторінку на верх
 * @param behavior Бажана поведінка
 */
export const scrollWindowToTop = async (behavior: 'auto' | 'smooth' | 'instant' = 'smooth') => {
	let positionDelta = 0;
	while (window.scrollY !== 0) {
		if (positionDelta === 0)
			window.scrollTo({
				top: 0,
				behavior: behavior as ScrollBehavior,
			});
		await promiseTimeOut(100);
		const oldPositionY = window.scrollY;
		await promiseTimeOut(100);
		positionDelta = oldPositionY - window.scrollY;
		// console.log(positionDelta);
	}
};

// export const scrollWindowToBottom = (behavior: 'auto' | 'smooth' = 'auto') =>
// 	window.scrollTo({
// 		top: 10000,
// 		behavior,
// 	});

// // export const moveControlToScreenCenter = async (control?: Element | null, highlight: boolean = false) => {
export const moveControlToScreenCenter = async (control?: Element | null) => {
	if (!control) return;
	control.scrollIntoView({ behavior: 'smooth', block: 'center' });
	// if (!highlight) return;
	// highlightControl(control);
};

// export const highlightControl = async (control?: Element | null, timeout?: number) => {
// 	if (!control) return;
// 	if (timeout) await promiseTimeOut(timeout);
// 	control.classList.add('animation-highlighted');
// 	await promiseTimeOut(500);
// 	control.classList.remove('animation-highlighted');
// };
