import React from 'react';
import { useParams } from 'react-router-dom';
import { TStringWithUndefined } from '../../init';

export interface IWithRubricIdByRoute {
	rubricId: TStringWithUndefined;
}
const WithRubricIdByRoute =
	<T extends IWithRubricIdByRoute>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithRubricIdByRoute>) => {
		// eslint-disable-next-line
		const { rubricId } = useParams();

		return <WrappedComponent {...(props as T)} rubricId={rubricId} />;
	};

export default WithRubricIdByRoute;
