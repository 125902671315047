import React, { useEffect, useMemo, useState } from 'react';
import { BsGlobe } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import Select, { ActionMeta, OptionProps, SingleValue, SingleValueProps, components } from 'react-select';
import { onSelectChangeSearchParam } from '../../features/app/appSlice';
import { DEFAULTS } from '../../init';
import { ISelectOption } from '../../interfaces/common';
import { COUNTRY_OPTIONS } from '../../interfaces/params';
import { RootState } from '../../store';
import CountryFlag from '../countryFlag';
import WithCountries, { IWithCountries } from '../hoc/withCountries';
import TextIcon from '../textIcon';

interface ICountryProps extends PropsFromRedux, IWithCountries {
	onCountryChange?: (value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => void;
	country: string;
	showLabel?: boolean;
	label?: string | JSX.Element;
	className?: string;
	containerClassName?: string;
	/**
	 * Якщо передано countryOptions, то використовуються саме вони
	 */
	countryOptions?: ISelectOption[];
	/**
	 * Якщо передано allCountries та не передано countryOptions, то виводяться всі можливі країни
	 * (крім заглушки "Інші країни")
	 */
	allCountries?: boolean;
}
const Country = ({
	country,
	onCountryChange,
	showLabel = true,
	countryOptions,
	countriesById,
	countriesAllIds,
	onSelectChangeSearchParam,
	className,
	containerClassName,
	allCountries = false,
	label = <TextIcon Icon={BsGlobe}>Країна джерел</TextIcon>,
}: ICountryProps) => {
	const userCountryOptions = useMemo((): ISelectOption[] | undefined => {
		if (countryOptions) return countryOptions;
		if (allCountries && countriesById)
			return countriesAllIds
				.map((countryId) => ({
					label: countriesById[countryId]?.title || '',
					value: countriesById[countryId]?.id || '',
				}))
				.filter(({ label }) => label !== '');
		return COUNTRY_OPTIONS;
	}, [countryOptions, allCountries, countriesById, countriesAllIds]);
	const [countries, setCountries] = useState(COUNTRY_OPTIONS);

	const countryValue = useMemo(
		() =>
			(userCountryOptions || countries).find(({ value }) => value === country) ||
			COUNTRY_OPTIONS[COUNTRY_OPTIONS.length - 1],
		[country, userCountryOptions, countries]
	);

	useEffect(() => {
		if (!countriesById) return;
		const listContainsCountry = COUNTRY_OPTIONS.some(({ value }) => value === country);
		if (listContainsCountry) return setCountries(COUNTRY_OPTIONS);
		const additionCountry = countriesById[country];
		if (!additionCountry) return setCountries(COUNTRY_OPTIONS);
		setCountries([
			...COUNTRY_OPTIONS,
			{
				label: additionCountry.title,
				value: additionCountry.id,
			},
		]);
	}, [country, countriesById]);

	return (
		<div title="Країна" className={containerClassName}>
			{showLabel && (
				<label htmlFor="search_country" className="form-label mb-0">
					{label}
				</label>
			)}
			<Select
				// className="basic-single"
				classNamePrefix="themed-select"
				// classNames={{
				// 	control:
				// }}
				// inputId="search_country"
				isSearchable
				options={userCountryOptions || countries}
				value={countryValue}
				onChange={onCountryChange || onSelectChangeSearchParam}
				placeholder="країна..."
				name="country"
				components={{ SingleValue: SingleElement, Option: CountryOption }}
				// styles={countryStyles}
				noOptionsMessage={() => DEFAULTS.noMatch}
				// menuIsOpen
				className={className}
			/>
		</div>
	);
};

const mapState = (state: RootState) => ({});

const mapDispatch = {
	onSelectChangeSearchParam,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithCountries(Country));

// const countryStyles: Partial<StylesConfig<ISelectOption, false, GroupBase<ISelectOption>>> = {
// 	control: (baseStyles) => ({
// 		...baseStyles,
// 		backgroundColor: 'var(--bs-body-bg)',
// 	}),
// 	singleValue: (baseStyles) => ({
// 		...baseStyles,
// 		color: 'var(--bs-body-color)',
// 	}),
// menuList: (baseStyles) => ({
// 	...baseStyles,
// 	zIndex: 2,
// 	// backgroundColor: 'var(--bs-body-bg)',
// }),
// };

const SingleElement = ({ children, data, ...props }: SingleValueProps<ISelectOption>) => (
	<components.SingleValue data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue country={data.value}>{children}</CountrySingleValue>
	</components.SingleValue>
);

const CountryOption = ({ children, data, ...props }: OptionProps<ISelectOption, false>) => (
	<components.Option data={data} {...props} className="d-flex align-items-center">
		<CountrySingleValue country={data.value}>{children}</CountrySingleValue>
	</components.Option>
);

interface CountrySingleValueProps extends React.BaseHTMLAttributes<HTMLDivElement> {
	country: string;
}
const CountrySingleValue = ({ country, children }: CountrySingleValueProps) => (
	<>
		<CountryFlag country={country} className="me-2" />
		{children}
	</>
);
