import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import SourceImage from '../../components/sourceImage';
import { DEFAULTS, IMessage } from '../../init';
import { MessageSmallTime } from '../../styledComponents';

interface IMessageSmallProps extends React.AllHTMLAttributes<HTMLDivElement> {
	message: IMessage;
	linkClassName?: string;
	/**
	 * Пошукова строка, яка буде передаватись діалоговому вікну з повідомленням
	 * для здійснення підсвітки
	 */
	term?: string;
	timeWidth?: number;
}
const MessageSmall = ({
	message: { id, title, hit_date, source_id, url, source_title },
	className,
	term,
	linkClassName,
	timeWidth = 54,
}: IMessageSmallProps) => {
	const date = dayjs(hit_date);
	return (
		<div className={classNames(className, 'd-flex align-items-start lh-sm')}>
			<MessageSmallTime className="text-body-tertiary text-nowrap flex-shrink-0" width={timeWidth}>
				{date.format(date.isSame(dayjs(), 'day') ? 'LT' : 'D MMM')}
			</MessageSmallTime>
			<Link
				to={`post/${id}`}
				state={term === undefined ? DEFAULTS.fromListState : { ...DEFAULTS.fromListState, term }}
				className={classNames('text-decoration-on-hover stretched-link flex-grow-1 message-title', linkClassName)}
				title={source_title}
			>
				{title}
			</Link>
			{/* <a href={url} target="_blank" rel="noreferrer" className="ms-auto"> */}
			<SourceImage sourceId={source_id} size="1.5em" className="flex-shrink-0 ms-1" />
			{/* </a> */}
		</div>
	);
};

export default MessageSmall;
