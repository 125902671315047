import { ICalendarData } from '../init';
import { IEntityEditMethod, QUERY_TAGS } from '../interfaces/common';
import { IRubricDic, TMutatingRubric, TRubricDictionary } from '../interfaces/rubrics';
import { searcherApi } from './searcherApi';

export interface IRubricStat {
	count: number;
	term: string;
	start: string;
	countByDate: ICalendarData[];
}

const rubricsApi = searcherApi.injectEndpoints({
	endpoints: (builder) => ({
		getRubrics: builder.query<TRubricDictionary, boolean>({
			query: (allRubrics) => `rubrics/list?all=${allRubrics ? 'On' : 'Off'}`,
			providesTags: (result) => {
				return result
					? [...result.ids.map((id) => ({ type: QUERY_TAGS.rubric, id })), { type: QUERY_TAGS.rubric, id: 'list' }]
					: [{ type: QUERY_TAGS.rubric, id: 'list' }];
			},
		}),
		getRubric: builder.query<IRubricDic, number | string>({
			query: (id) => `rubric/${id}`,
			/**
			 * В даному випадку наче-то не потрібно оновлювати запит, тому що вікно діалогу
			 * редагування рубрики в разі успішної операції зразу закривається.
			 * Але! В даному випадку дані для рубрики не оновлюються і, зважаючи на кешування,
			 * при повторному відкритті діалогу редагування тієї ж рубрики будуть відображені
			 * старі атрибути рубрики, тобто ті, які вона мала до редагування!
			 */
			providesTags: (result, error, id) => [{ type: QUERY_TAGS.rubric, id }],
		}),
		updateRubric: builder.mutation<any, TMutatingRubric & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: `edit/rubric`,
				method,
				body: rest,
			}),
			/**
			 * Оновлюємо id тільки при зміні атрибутів рубрики.
			 * В іншому разі оновлення конкретної рубрики не потрібне.
			 */
			invalidatesTags: (result, error, { ID, method }) => [
				{ type: QUERY_TAGS.rubric, id: method === 'PUT' ? ID : 'list' },
			],
		}),
		setFavorite: builder.mutation<any, IEntityEditMethod & { ID: number }>({
			query: ({ method, ID }) => ({
				url: `edit/rubric/fav/${ID}`,
				method,
			}),
			/**
			 * Зважаючи на те, що список змінюється тільки відмітка про одну улюблену рубрику,
			 * її і оновлюємо.
			 */
			invalidatesTags: (result, error, { ID, method }) => [{ type: QUERY_TAGS.rubric, id: ID }],
		}),
	}),
});

export const { useGetRubricsQuery, useGetRubricQuery, useUpdateRubricMutation, useSetFavoriteMutation } = rubricsApi;
