import React, { Suspense, useState } from 'react';
import { BsLifePreserver } from 'react-icons/bs';
import styled from 'styled-components';
import FixedPanelButton from '../../components/FixedPanelButton';
import Preloader from '../../components/Preloader';

const LazyIssueDialog = React.lazy(() => import('./issueDialog'));

// interface IIssueCreatorProps extends React.AllHTMLAttributes<HTMLDivElement> {}
// const IssueCreator = ({}: IIssueCreatorProps) => {
const IssueCreator = () => {
	const [show, setShow] = useState(false);

	const onNewIssueClick = () => setShow(true);

	const onCloseDIalog = () => setShow(false);

	return (
		<>
			<IssueButton
				Icon={BsLifePreserver}
				onClick={onNewIssueClick}
				title="Надіслати розробнику повідомлення про помилку, пропозицію, тощо"
			/>
			{show && (
				<Suspense fallback={<Preloader fixed />}>
					<LazyIssueDialog onClose={onCloseDIalog} />
				</Suspense>
			)}
		</>
	);
};

export default IssueCreator;

const IssueButton = styled(FixedPanelButton)`
	--fixed-button-color: var(--bs-orange, orange);
	--fixed-button-opacity: 1;
`;
