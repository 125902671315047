import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { DEFAULTS } from '../init';
import { EntityIcon } from '../styledComponents';

interface IRubricImageProps extends React.BaseHTMLAttributes<HTMLImageElement> {
	size?: string;
	rounded?: string;
	src: string | null;
	withShadow?: boolean;
}
const RubricImage = React.memo(
	({ className, withShadow = true, rounded = 'rounded', size = '3em', src, ...rest }: IRubricImageProps) => {
		const [imgSrc, setImgSrc] = useState(() => (src ? `img/rubrics/${src}` : DEFAULTS.rubricImg));

		useEffect(() => {
			setImgSrc(src ? `img/rubrics/${src}` : DEFAULTS.rubricImg);
		}, [src]);

		const onLoadImageError = useCallback(() => setImgSrc(DEFAULTS.rubricImg), []);

		return (
			<EntityIcon
				size={size}
				className={classNames('flex-shrink-0', rounded, className)}
				src={imgSrc}
				onError={onLoadImageError}
				loading="lazy"
				withShadow={withShadow}
				{...rest}
			/>
		);
	}
);

export default RubricImage;
