import styled from 'styled-components';

interface IClampedText {
	linesCount: number;
	lineHeight?: number;
}
export const ClampedText = styled.div<IClampedText>`
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.linesCount};
	/* line-clamp: 2; */
	-webkit-box-orient: vertical;
	overflow: hidden;
	line-height: ${({ lineHeight = 1.5 }) => lineHeight + 'em'};
	max-height: ${({ lineHeight = 1.5, linesCount }) => lineHeight * linesCount + 'em'};
`;

interface ITopStickyDivProps {
	top?: string;
	zIndex?: number;
	bg?: string;
}
export const TopStickyDiv = styled.div<ITopStickyDivProps>`
	position: sticky;
	top: ${(props) => props.top || '64px'};
	z-index: ${(props) => props.zIndex || 1};
	background-color: ${(props) => props.bg || 'var(--bs-body-bg)'};
`;

interface IBottomStickyDivProps {
	bottom?: string;
	zIndex?: number;
	bg?: string;
}
export const BottomStickyDiv = styled.div<IBottomStickyDivProps>`
	position: sticky;
	bottom: ${(props) => props.bottom || '0'};
	z-index: ${(props) => props.zIndex || 1};
	background-color: ${(props) => props.bg || 'var(--bs-body-bg)'};
`;

interface IGridByRowsProps {
	baseSize: string;
}
export const GridAutoFill = styled.div<IGridByRowsProps>`
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: ${(props) => `repeat(auto-fill, minmax(${props.baseSize}, 1fr))`};
`;

interface IEntityIcon {
	size: string;
	withShadow?: boolean;
}
export const EntityIcon = styled.img<IEntityIcon>`
	width: ${({ size }) => size};
	height: ${({ size }) => size};
	flex: 0 0 auto;
	filter: ${({ withShadow }) => (withShadow ? 'drop-shadow(1px 1px 3px rgba(0,0,0,0.5))' : 'unset')};
`;

interface IMessageSmallTimeProps {
	width: number;
}
export const MessageSmallTime = styled.span<IMessageSmallTimeProps>`
	width: ${({ width }) => `${width}px`};
`;
