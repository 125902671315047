import React from 'react';

interface IIndexedOptionsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	options: {
		label: string;
		value?: string;
	}[];
	valuePattern?: 'index' | 'value';
}
const IndexedOptions = ({ options, valuePattern = 'index' }: IIndexedOptionsProps) => {
	return (
		<>
			{options.map(({ label, value }, index) => (
				<option key={index} value={valuePattern === 'index' ? index : value || index}>
					{label}
				</option>
			))}
		</>
	);
};

export default IndexedOptions;
