import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Col } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { Outlet, useLocation, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import EntityTitle from '../../components/EntityTitle';
import FetchError from '../../components/FetchError';
import PageTitle from '../../components/PageTitle';
import WithRubric, { IWithRubric } from '../../components/hoc/withRubric';
import WithRubricIdByRoute from '../../components/hoc/withRubricIdByRoute';
import { TOnMessagesFetchedFunction } from '../../components/hoc/withSearchResponse';
import Country from '../../components/params/country';
import ParamsContainer from '../../components/params/paramsContainer';
import Sources from '../../components/params/sources';
import TextIcon from '../../components/textIcon';
import UpdateButton from '../../components/updateButton';
import {
	DEFAULTS,
	TStringWithUndefined,
	USER_GMT,
	YYYYMMDD,
	currentYMD,
	dtFromYMD,
	getGMTForDate,
	prepareSearchTerm,
} from '../../init';
import { DEFAULT_SEARCH_PARAMS, SORT_INDEX_HIT_DATE_DESC, SORT_INDEX_SCORE_DESC } from '../../interfaces/params';
import { IRubricDic, TRubricStatisticContextType } from '../../interfaces/rubrics';
import { scrollWindowToTop } from '../../scrolls';
import { useMessagesFacetsForMonthQuery } from '../../services/messages';
import { RootState } from '../../store';
import { TopStickyDiv } from '../../styledComponents';
import { getAverageDate } from '../../utils';
import { changeParams, changeSources, clearParamsWithoutTerm } from '../app/appSlice';
import Calendar from '../calendar/Calendar';
import MessageListContainer from '../messages/MessageListContainer';
import {
	changePreparedSearchString,
	changeRawQueryString,
	changeSelectedYMD,
	changeTs,
	clearSearchState,
	getPeriodDates,
} from '../searcher/searcherSlice';
import StatisticButton from '../statistic/StatisticButton';
import VideoList from '../video/VideoList';
import RubricInfo from './RubricInfo';
import RubricPeriod from './RubricPeriod';

// &sources=source_id:(573+458+435+498+181+417)
const getQueryString = (
	rubric: IRubricDic,
	periodIndex: number,
	start_date: TStringWithUndefined,
	end_date: TStringWithUndefined,
	sources: string,
	country: string | null
) => {
	const params = new URLSearchParams({
		TZ: USER_GMT,
		sort: 'hit_date desc, id asc',
		// period: `hit_date:[${hitDate} TO ${hitDate}+1DAY}`,
		severity: '0',
		term: prepareSearchTerm(rubric.QUERY || ''),
	});
	if (periodIndex !== 0)
		params.append('period', `hit_date:[${getPeriodDates(periodIndex, start_date, end_date).join(' TO ')}]`);

	if (sources) params.append('sources', `source_id:(${sources})`);
	if (country) params.append('country', `country:${country}`);

	return params.toString();
};

interface IRubricMessageListProps extends PropsFromRedux, IWithRubric {}
const RubricMessageList = ({
	changeTs,
	changeRawQueryString,
	changeSelectedYMD,
	clearSearchState,
	pageNo,
	selectedYMD,
	isToday,
	queryString,
	changePreparedSearchString,
	ts,
	selectedSources,
	changeSources,
	country,
	changeParams,
	clearParamsWithoutTerm,
	rubricId,
	rubric,
	rubricIsFetching,
	rubricError,
}: IRubricMessageListProps) => {
	// const { rubricId } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [firstRender, setFirstRender] = useState(true);
	const [medianMessagesDate, setMedianMessagesDate] = useState<Date | null>();
	const [tvSearchParams, setTvSearchParams] = useState<URLSearchParams | null>(null);
	const [videoEnabled, setVideoEnabled] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const matchStatistic = !!useMatch('/rubric/:rubricId/statistic/*');
	const [statisticSearchParams, setStatisticSearchParams] = useState('');
	// const { data: rubric, error: rubricError, isFetching: rubricFetching } = useGetRubricQuery(rubricId || '0');
	const {
		data: facetsResponse,
		isFetching: facetsFetching,
		error: facetsError,
		refetch: refetchFacets,
	} = useMessagesFacetsForMonthQuery(
		{
			df: 'body',
			query: prepareSearchTerm(rubric?.QUERY ?? ''),
			start: dayjs(selectedYMD, YYYYMMDD).startOf('month').toISOString(),
			TZ: getGMTForDate(dayjs(selectedYMD, YYYYMMDD).startOf('month').toISOString()),
			sources: selectedSources,
			country: country,
			// country: searchParams.get('country'),
		},
		{ skip: !selectedYMD || matchStatistic }
	);

	const onFetched: TOnMessagesFetchedFunction = useCallback(
		({ countChanged, searchResponse: { response } }) => {
			if (countChanged && isToday) refetchFacets();
			setMedianMessagesDate(
				response && response.docs.length !== 0 ? getAverageDate(response.docs.map(({ hit_date }) => hit_date)) : null
			);
			// if (response && response.docs.length !== 0) {
			// 	const averageDate = getAverageDate(response.docs.map(({ hit_date }) => hit_date));
			// 	setMedianMessagesDate(averageDate);
			// } else {
			// 	setMedianMessagesDate(undefined);
			// }
		},
		[refetchFacets, isToday]
	);

	useEffect(() => {
		if (!rubric) return;
		const newSelectedDate = dtFromYMD(searchParams.get('date'));

		changeRawQueryString(
			!matchStatistic
				? getQueryString(
						rubric,
						DEFAULTS.userCustomPeriodIndex,
						searchParams.get('date') || currentYMD(),
						searchParams.get('date') || currentYMD(),
						selectedSources,
						country
						// searchParams.get('country')
				  )
				: getQueryString(
						rubric,
						parseInt(searchParams.get('period_index') || DEFAULT_SEARCH_PARAMS.period_index),
						searchParams.get('start_date') ?? undefined,
						searchParams.get('end_date') ?? undefined,
						selectedSources,
						country
						// searchParams.get('country')
				  )
		);
		changeSelectedYMD(newSelectedDate.format(YYYYMMDD));
		scrollWindowToTop();
	}, [rubric, searchParams, selectedSources, country, matchStatistic]); // eslint-disable-line

	useEffect(() => {
		scrollWindowToTop();
	}, [pageNo]);

	const onBackClick = () => {
		location.state?.fromList ? navigate(-1) : navigate('..', { replace: true });
	};

	const onUpdate = useCallback(() => {
		changeTs();
	}, [changeTs]);

	useEffect(() => {
		if (!rubric) return;

		changePreparedSearchString(prepareSearchTerm(rubric.QUERY || ''));
	}, [rubric]); // eslint-disable-line

	useEffect(() => {
		return () => {
			clearSearchState();
			// changeSources([]);
			clearParamsWithoutTerm();
			// changeParams({ country: '' });
			// changeParams(DEFAULT_SEARCH_PARAMS);
		};
	}, []); // eslint-disable-line

	useEffect(() => {
		const initialCountry = (searchParams.get('country') || '').toUpperCase();
		// if (initialCountry !== country)
		changeParams({ country: initialCountry });

		const initialSources = searchParams.get('sources') || '';
		// if (initialSources !== selectedSources)
		changeSources(
			initialSources
				? initialSources
						.split(/\s+/)
						.map((n) => parseInt(n, 10))
						.filter(Boolean)
				: []
		);

		setFirstRender(false);
	}, [searchParams]); // eslint-disable-line

	useEffect(() => {
		setStatisticSearchParams(getStatisticSearchParams(country, selectedSources));

		if (firstRender) return;

		if (country) searchParams.set('country', country);
		else searchParams.delete('country');

		if (selectedSources) searchParams.set('sources', selectedSources);
		else searchParams.delete('sources');

		setSearchParams(searchParams, { replace: true });
	}, [country, selectedSources]); // eslint-disable-line

	useEffect(() => {
		if (!rubric || !rubric.QUERY || !selectedYMD) return setTvSearchParams(null);
		setTvSearchParams(
			new URLSearchParams({
				term: rubric.QUERY,
				severity: '0',
				period_index: DEFAULTS.userCustomPeriodIndex.toString(),
				start_date: selectedYMD,
				end_date: selectedYMD,
				sort_index: isToday ? SORT_INDEX_HIT_DATE_DESC : SORT_INDEX_SCORE_DESC,
			})
		);
	}, [rubric, selectedYMD, isToday]);

	useEffect(() => {
		setVideoEnabled(
			!matchStatistic &&
				pageNo === 0 &&
				!searchParams.get('sources') &&
				!searchParams.get('country') &&
				!!tvSearchParams
		);
	}, [pageNo, searchParams, tvSearchParams, matchStatistic]);

	// В поточній даті час встановлюється на 12:00 (так безпечніше зважаючи на перевід годинника)
	const selectedDate = useMemo(() => dtFromYMD(selectedYMD).add(12, 'hours'), [selectedYMD]);

	// #region Render
	if (!rubricId) return null;

	return (
		<>
			{facetsError && <FetchError error={facetsError} />}
			{rubric && <PageTitle title={`${rubric.TITLE}, ${selectedDate.format('L')}`} />}
			<Col xs={12} md={7} lg={8} className="vstack">
				{rubric && <EntityTitle className="d-md-none mb-2">Рубрика: &laquo;{rubric.TITLE}&raquo;</EntityTitle>}
				{!matchStatistic && (
					<div className={isToday ? 'order-1' : undefined}>
						<MessageListContainer
							queryString={queryString}
							pageNo={pageNo}
							ts={isToday ? ts : 0}
							messageOrder="R"
							waitMessage="Пошук повідомлень рубрики"
							mutateState
							onFetched={onFetched}
						>
							<ButtonGroup size="sm" className="order-0">
								<Button variant="outline-primary" onClick={onBackClick}>
									<TextIcon Icon={BsArrowLeft}>
										<span className="d-none d-xl-inline">До списку рубрик</span>
									</TextIcon>
								</Button>
								<StatisticButton title={<span className="d-none d-xl-inline">Діаграми</span>} />
								{dayjs().isSame(selectedDate, 'day') && (
									<UpdateButton onUpdate={onUpdate} autoUpdate={pageNo === 0} adaptive />
								)}
							</ButtonGroup>
						</MessageListContainer>
					</div>
				)}
				{videoEnabled && !matchStatistic && tvSearchParams && (
					<VideoList
						searchParams={tvSearchParams}
						orderBy={isToday ? 'D' : 'S'}
						polling={isToday}
						medianMessagesDate={isToday ? medianMessagesDate : null}
					/>
				)}
				{(!matchStatistic || !firstRender) && <Outlet context={statisticSearchParams as TRubricStatisticContextType} />}
			</Col>
			<Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 5, offset: 0 }} lg={4}>
				<RubricInfo
					rubricId={rubricId}
					rubric={rubric}
					rubricIsFetching={rubricIsFetching}
					rubricError={rubricError}
					className="mb-2"
				/>
				<TopStickyDiv>
					{!matchStatistic && (
						<Calendar
							selectedDate={selectedDate}
							data={facetsResponse?.facets}
							searching={facetsFetching}
							linkState={location.state}
							TZ={facetsResponse?.TZ}
						/>
					)}
					<ParamsContainer title="Фільтрація" className="mt-1">
						{matchStatistic && <RubricPeriod />}
						{/* <RubricCountryParam /> */}
						<Country country={country} />
						<Sources />
					</ParamsContainer>
				</TopStickyDiv>
			</Col>
		</>
	);
};

const mapState = (state: RootState) => ({
	pageNo: state.searcher.pageNo,
	selectedYMD: state.searcher.selectedYMD,
	isToday: state.searcher.selectedDateIsToday,
	queryString: state.searcher.queryString,
	ts: state.searcher.ts,
	selectedSources: state.app.searchParams.sources,
	country: state.app.searchParams.country,
});

const mapDispatch = {
	clearSearchState,
	changeRawQueryString,
	changeSelectedYMD,
	changeTs,
	changePreparedSearchString,
	changeSources,
	changeParams,
	clearParamsWithoutTerm,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default WithRubricIdByRoute(WithRubric(connector(RubricMessageList)));

const getStatisticSearchParams = (country: string | null, sources: string | null) => {
	if (!(country || sources)) return '';
	const params = new URLSearchParams();
	if (country) params.append('country', country);
	if (sources) params.append('sources', sources);
	return `?${params.toString()}`;
};
