import React from 'react';
import { BsStopwatch } from 'react-icons/bs';
import FetchError from '../../components/FetchError';
import Caption from '../../components/caption';
import WithCountries, { IWithCountries } from '../../components/hoc/withCountries';
import WithSearchResponse, { IWithSearchResponse } from '../../components/hoc/withSearchResponse';
import WithSources, { IWithSources } from '../../components/hoc/withSources';
import { getCountryTitle } from '../../facetUtils';
import imgNoMessages from '../../img/undraw_no_data_re_kwbl.svg';
import { DEFAULTS, ISearchResponse } from '../../init';
import { TMessageListOrder } from '../../interfaces/params';
import PaginationPanel from '../searcher/pagination';
import SearchHeader from '../searcher/searchHeader';
import MessageBig from './MessageBig';

/**
 * Компонент для рендерингу списку повідомлень та додаткової інформації про пошук.
 * Даний компонент рендерить повну версію повідомлень.
 */

interface IMessageListProps
	extends IWithCountries,
		IWithSources,
		IWithSearchResponse,
		React.AllHTMLAttributes<HTMLDivElement> {
	messageOrder?: TMessageListOrder;
	noPostsMessage?: React.ReactNode;
	headerClassName?: string;
	searchResponse: ISearchResponse | undefined;
	pageNo: number;
	withMatching?: boolean;
	listClassName?: string;
	itemClassName?: string;
	showInfo?: boolean;
}
const MessageList = ({
	countriesById,
	sourcesById,
	messageOrder = 'D',
	title,
	noPostsMessage,
	children,
	headerClassName,
	searchResponse,
	pageNo,
	withMatching = true,
	listClassName = 'd-flex flex-column gap-2rem',
	isFetching,
	showInfo = true,
	itemClassName,
	withPagination = true,
}: IMessageListProps) => {
	if (!searchResponse) return null;

	const {
		responseHeader: { QTime, partialResults },
		response,
		error,
		highlighting,
	} = searchResponse;

	return (
		<>
			{showInfo && (
				<div className="search-info mb-4">
					<SearchHeader numFound={response?.numFound ?? 0} searchTime={QTime} title={title} className={headerClassName}>
						{children}
					</SearchHeader>
					{error && <FetchError error={error} />}
					{response?.numFound === 0 && (
						<Caption imgSrc={imgNoMessages} alt="Результати відсутні" className="text-warning mt-3">
							<div className="text-center">{noPostsMessage}</div>
						</Caption>
					)}
					{/* <div className="d-flex justify-content-between align-items-center">
					<DropdownButton
						id="btnDownloadResults"
						title={
							<TextIcon Icon={BsDownload} className="my-n1">
								Завантажити
							</TextIcon>
						}
						variant="outline-secondary"
						size="sm"
						className="flex-shrink-0 d-none"
					>
						<Dropdown.Item href={`api/download.php?${queryString}&format=html`}>В форматі html</Dropdown.Item>
						<Dropdown.Item href={`api/download.php?${queryString}&format=csv`}>В форматі csv</Dropdown.Item>
					</DropdownButton>
				</div> */}
					{partialResults && (
						<div className="alert alert-info mt-1">
							<BsStopwatch className="mt-n1" /> <span className="fw-bolder">Часткові результати.</span> Пошук було
							примусово припинено у&nbsp;зв’язку з&nbsp;тим, що&nbsp;виконання його у&nbsp;повному обсязі потребувало
							надто багато часу. Якщо&nbsp;результати Вас не&nbsp;задовольняють, спробуйте виконати цей&nbsp;же пошук
							ще&nbsp;раз.
						</div>
					)}
				</div>
			)}
			{response && (
				// <section className={classNames(listClassName, isFetching && 'opacity-25')}>
				<section className={listClassName}>
					{response.docs.map((doc, index) => {
						const source = sourcesById[doc.source_id];
						const country = source?.country || doc.country;
						return (
							<MessageBig
								key={doc.id}
								message={doc}
								index={
									messageOrder === 'D'
										? DEFAULTS.messagesPerPage * pageNo + index + 1
										: response.numFound - DEFAULTS.messagesPerPage * pageNo - index
								}
								highlighting={highlighting ? highlighting[doc.id]?.body : undefined}
								matching={withMatching ? Math.round((doc.score / (response.maxScore || 1e9)) * 100) : undefined}
								countryTitle={`${getCountryTitle(country, countriesById)} (${country})`}
								source={source}
								className={itemClassName}
							/>
						);
					})}
				</section>
			)}
			{withPagination && <PaginationPanel />}
		</>
	);
};

// export const RawMessageList = WithSources(WithCountries(MessageList));

export default WithSearchResponse(WithSources(WithCountries(MessageList)));
