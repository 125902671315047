import classNames from 'classnames';
import React from 'react';

interface IParamsContainerProps extends React.AllHTMLAttributes<HTMLDivElement> {
	legendChildren?: React.ReactNode;
}
const ParamsContainer = ({ title, children, className, legendChildren }: IParamsContainerProps) => {
	return (
		<fieldset className={classNames(className, 'inactive-transparent')}>
			<legend className={classNames(legendChildren ? 'd-flex gap-2 align-items-center' : undefined, 'mb-1')}>
				<div className="text-truncate">{title}</div>
				{legendChildren}
			</legend>
			<div className="d-flex flex-column gap-2 mt-n1">{children}</div>
		</fieldset>
	);
};

export default ParamsContainer;
