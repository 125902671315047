import React from 'react';
import { Pagination } from 'react-bootstrap';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import TextIcon from '../../components/textIcon';
import imgEndOfMessages from '../../img/undraw_complete_task_u2c3.svg';
import { scrollWindowToTop } from '../../scrolls';
import { RootState } from '../../store';
import { changePageNo } from '../searcher/searcherSlice';

interface IPaginationPanelProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {
	withScrolling?: boolean;
}
const PaginationPanel = ({ pageNo, pageCount, changePageNo, withScrolling = false }: IPaginationPanelProps) => {
	const PAGE_DELTA = 3;
	let startPage = pageNo - PAGE_DELTA;
	let endPage = pageNo + PAGE_DELTA;
	let printStartHellip = true;
	let printEndHellip = true;
	if (startPage <= 1) {
		startPage = 0;
		printStartHellip = false;
	}
	if (pageCount - 2 <= endPage) {
		endPage = pageCount - 1;
		printEndHellip = false;
	}

	const onPageClick = (event: React.MouseEvent<HTMLElement>) => {
		const pageItem = (event.target as HTMLElement).closest('.page-link') as HTMLAnchorElement;
		if (!pageItem) return;
		if (pageItem.closest('page-item')?.classList.contains('disabled')) return;
		const { pageNo } = pageItem.dataset;
		if (!pageNo) return;
		event.preventDefault();
		event.stopPropagation();
		changePageNo(parseInt(pageNo, 10));
		if (withScrolling) scrollWindowToTop();
	};

	return (
		<>
			{pageNo === pageCount - 1 && (
				<div className="text-center mb-4">
					<img src={imgEndOfMessages} className="w-25" alt="Кінець повідомлень" />
				</div>
			)}
			{1 < pageCount && (
				<div className="d-flex justify-content-center my-3">
					<ul className="pagination my-0" onClick={onPageClick}>
						<PaginationButton pageNo={pageNo - 1} disabled={pageNo === 0}>
							<TextIcon Icon={BsArrowLeft} className="my-n1">
								Назад
							</TextIcon>
						</PaginationButton>
						{printStartHellip && (
							<>
								<PaginationButton pageNo={0} active={pageNo === 0} />
								<PaginationHellip />
							</>
						)}
						{[...new Array(endPage - startPage + 1)].map((_value, index) => {
							const currentPageNo = index + startPage;
							return <PaginationButton key={currentPageNo} pageNo={currentPageNo} active={currentPageNo === pageNo} />;
						})}
						{printEndHellip && <PaginationHellip />}
						<PaginationButton pageNo={pageNo + 1} disabled={pageNo === pageCount - 1}>
							<TextIcon Icon={BsArrowRight} iconLast className="my-n1">
								Вперед
							</TextIcon>
						</PaginationButton>
					</ul>
				</div>
			)}
		</>
	);
};

const mapState = (state: RootState) => ({
	pageNo: state.searcher.pageNo,
	pageCount: state.searcher.pageCount,
});

const mapDispatch = {
	changePageNo,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PaginationPanel);

// interface IPaginationHellipProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const PaginationHellip = () => {
	return (
		<li className="page-item">
			<button className="page-link text-secondary" disabled>
				&hellip;
			</button>
		</li>
	);
};

interface IPaginationButtonProps extends React.AllHTMLAttributes<HTMLDivElement> {
	pageNo: number;
	active?: boolean;
	disabled?: boolean;
	text?: string | JSX.Element;
}
const PaginationButton = ({
	pageNo,
	active,
	disabled,
	text = (pageNo + 1).toString(),
	children,
}: IPaginationButtonProps) => {
	return (
		<Pagination.Item
			disabled={disabled}
			// disabled={disabled || active}
			// className={classNames('page-item', active && 'bg-primary text-white')}
			data-page-no={pageNo}
			active={active}
		>
			{children || text}
		</Pagination.Item>
		// <li className={classNames('page-item', (disabled || active) && 'disabled')} data-page-no={pageNo}>
		// 	<button className={classNames('btn-page-no page-link', active && 'bg-primary text-white')}>
		// 		{children || text}
		// 	</button>
		// </li>
	);
};

// <li className={classNames('page-item', (disabled || active) && 'disabled')} data-page-no={pageNo}>
// 	<button className={classNames('btn-page-no page-link', active && 'bg-primary text-white')}>
// 		{children || text}
// 	</button>
// </li>;
