import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';
import styled from 'styled-components';
import { IDictionary, TEntityId } from '../../interfaces/common';
import { ISourceDic } from '../../interfaces/dict';
import TextIcon from '../textIcon';
import SourceItem from './sourceItem';

interface ISourceListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	sources: number[];
	removeSource: ActionCreatorWithPayload<number, string>;
	sourcesIds: TEntityId<number>[];
	sourcesById: IDictionary<ISourceDic>;
	excluded: boolean;
}
const SourceList = ({ sourcesIds, sourcesById, sources, removeSource, excluded, ...rest }: ISourceListProps) => {
	const userSources = useMemo(
		() => sources.map((sourceId) => sourcesById[Math.abs(sourceId)]).filter(Boolean),
		[sources, sourcesById]
	);

	const onSourceRemoveClick = useCallback(
		async (event: React.MouseEvent<HTMLDivElement>) => {
			if (!(event.target as HTMLElement).closest('.sources__btn-remove')) return;
			const element = (event.target as HTMLElement).closest('.sources__source-item') as HTMLDivElement;
			if (!element) return;
			const { sourceId } = element.dataset;
			if (!sourceId) return;
			removeSource(parseInt(sourceId) * (excluded ? -1 : 1));
		},
		[excluded, removeSource]
	);

	if (userSources.length === 0) return null;

	return (
		<>
			<SourceListContainer
				className={classNames('d-flex flex-column gap-2 mb-2', excluded && 'border border-warning rounded')}
				onClick={onSourceRemoveClick}
				{...(rest as any)}
			>
				{userSources.map((source) => (source ? <SourceItem key={source.id} source={source} /> : null))}
			</SourceListContainer>
			{excluded && (
				<Alert variant="warning" className="text-small p-2 mb-2">
					<TextIcon Icon={BsExclamationTriangle} /> Повідомлення даних джерел виключено з результатів пошуку.
				</Alert>
			)}
		</>
	);
};

export default SourceList;

const SourceListContainer = styled.div`
	max-height: 300px;
	max-height: 25vh;
	overflow: auto;
	/* font-size: 0.8em; */
`;
