import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { BsCalendarRange } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { changeParams, onChangeSearchParam } from '../../features/app/appSlice';
import { DEFAULTS, currentYMD, periodIsValid } from '../../init';
import { PERIOD_OPTIONS } from '../../interfaces/params';
import { RootState } from '../../store';
import SelectOptions from '../selectOptions';
import TextIcon from '../textIcon';

interface IPeriodProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const Period = ({ periodIndex, startDate, endDate, onChangeSearchParam, changeParams }: IPeriodProps) => {
	const [isPeriodValid, setIsPeriodValid] = useState(() => periodIsValid(startDate, endDate));

	const onPeriodIndexChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newPeriodIndex = (event.target as HTMLSelectElement).value;
		const selectedDate = parseInt(newPeriodIndex) === DEFAULTS.userCustomPeriodIndex ? currentYMD() : undefined;
		changeParams({
			period_index: newPeriodIndex,
			start_date: selectedDate,
			end_date: selectedDate,
		});
	};

	useEffect(() => {
		setIsPeriodValid(periodIsValid(startDate, endDate));
	}, [startDate, endDate]);

	return (
		<>
			<div>
				<label htmlFor="search_dateSelect" className="form-label mb-0">
					<TextIcon Icon={BsCalendarRange}>Дата публікації</TextIcon>
				</label>
				<select
					id="search_dateSelect"
					className="form-select"
					name="period_index"
					autoComplete="off"
					value={periodIndex}
					onChange={onPeriodIndexChange}
				>
					<SelectOptions options={PERIOD_OPTIONS} />
				</select>
			</div>
			{periodIndex === DEFAULTS.userCustomPeriodIndex && (
				<div className="row mb-3">
					<div className="col-6">
						<input
							type="date"
							title="З"
							className={classNames('form-control', !isPeriodValid && 'text-danger border-danger')}
							name="start_date"
							id="search_startDate"
							autoComplete="off"
							min="2002-01-01"
							max={currentYMD()}
							value={startDate || currentYMD()}
							onChange={onChangeSearchParam}
						/>
					</div>
					<div className="col-6">
						<input
							type="date"
							title="По (включно)"
							className={classNames('form-control', !isPeriodValid && 'text-danger border-danger')}
							name="end_date"
							id="search_endDate"
							autoComplete="off"
							min="2002-01-01"
							max={currentYMD()}
							value={endDate || currentYMD()}
							onChange={onChangeSearchParam}
						/>
					</div>
					{!isPeriodValid && (
						<div className="col-12 invalid-feedback d-block">Кінцева дата має бути не менша за початкову.</div>
					)}
				</div>
			)}
		</>
	);
};

const mapState = (state: RootState) => ({
	periodIndex: parseInt(state.app.searchParams.period_index),
	startDate: state.app.searchParams.start_date,
	endDate: state.app.searchParams.end_date,
});

const mapDispatch = {
	onChangeSearchParam,
	changeParams,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Period);
