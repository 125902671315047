import React from 'react';
import { BsEyeSlash } from 'react-icons/bs';
import { ISourceDic } from '../../interfaces/dict';
import { ClampedText } from '../../styledComponents';
import CountryFlag from '../countryFlag';
import LinkButton from '../linkButton';
import SourceImage from '../sourceImage';

interface ISourceItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	source: ISourceDic;
}
const SourceItem = ({ source }: ISourceItemProps) => {
	const { id, title, country } = source;
	return (
		<div className="sources__source-item d-flex align-items-center gap-1" data-source-id={id}>
			<SourceItemInner sourceId={id} country={country} closeButton>
				{title}
			</SourceItemInner>
		</div>
	);
};

export default SourceItem;

interface ISourceItemInner extends React.AllHTMLAttributes<HTMLDivElement> {
	sourceId: number;
	country: string;
	closeButton?: boolean;
}
export const SourceItemInner = React.memo(({ sourceId, country, closeButton = false, children }: ISourceItemInner) => (
	<>
		<SourceImage sourceId={sourceId} size="2em" />
		<ClampedText linesCount={2} className="small-text lh-sm">
			{children}
		</ClampedText>
		<CountryFlag country={country} className="ms-auto" />
		{closeButton && (
			<LinkButton className="text-danger sources__btn-remove">
				<BsEyeSlash />
			</LinkButton>
		)}
	</>
));
