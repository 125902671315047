import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { YYYYMMDD } from '../../init';

interface ICalendarDateItemProps {
	date?: Dayjs;
	count?: number;
	startShift?: number;
	dayClassName?: string;
	selected?: boolean;
	today?: boolean;
	weekDay?: string;
	linkState?: any;
	searchParams?: URLSearchParams;
	TZ?: string;
}
const CalendarDateItem = ({
	date,
	count,
	startShift,
	dayClassName,
	selected = false,
	weekDay,
	linkState,
	today,
	searchParams,
	TZ = '',
}: ICalendarDateItemProps) => {
	const [searchPart, setSearchPart] = useState('');
	useEffect(() => {
		if (searchParams === undefined) return;
		if (!date) return setSearchPart('');

		const params = new URLSearchParams(searchParams);
		if (today) params.delete('date');
		else params.set('date', date.format(YYYYMMDD));

		setSearchPart(params.toString() === '' ? '' : `?${params.toString()}`);
	}, [searchParams, today, date]);

	return (
		<GridItem
			style={{ gridColumn: startShift }}
			className={classNames(
				'position-relative',
				selected && 'text-bg-primary',
				today && 'calendar__item_today',
				'rounded'
			)}
			start={startShift}
		>
			{!weekDay ? (
				<>
					{count !== 0 ? (
						// <Button variant="link" data-day={date} className="calendar__day p-0" disabled={count === 0}>
						// 	{date}
						// </Button>
						<Link
							to={searchPart}
							className={classNames('text-decoration-none stretched-link', selected && 'link-light')}
							replace
							state={linkState}
						>
							{date?.date()}
						</Link>
					) : (
						<span className={classNames(!selected && 'text-body-tertiary')}>{date?.date()}</span>
					)}
				</>
			) : (
				<span className={dayClassName}>{weekDay}</span>
			)}
			{!weekDay && (
				<CountItem
					className={classNames(
						'border-top',
						!selected && 'border-secondary-subtle',
						!selected && (count !== 0 ? 'text-body-secondary' : 'text-body-tertiary')
					)}
				>
					{count || '-'}
				</CountItem>
			)}
		</GridItem>
	);
};

export default CalendarDateItem;

interface IGridItem {
	start?: number;
}
const GridItem = styled.div<IGridItem>`
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-column: ${({ start }) => start || 'unset'};
	border: 1px solid var(--bc-calendar-day, transparent);
	.calendar__week_em {
		color: var(--bs-danger);
	}
	&.calendar__item_today {
		--bc-calendar-day: var(--bs-primary);
	}
`;

const CountItem = styled.span`
	font-size: 0.6em;
	width: 100%;
	text-align: center;
`;
