import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React, { useCallback, useMemo, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Select, { ActionMeta, InputActionMeta, OptionProps, SingleValue, components } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { DEFAULTS } from '../../init';
import { IDictionary, TEntityId } from '../../interfaces/common';
import { ISourceDic, ISourceSelectOption } from '../../interfaces/dict';
import DropdownPreSources from './dropdownPreSources';
import { SourceItemInner } from './sourceItem';

const MIN_TERM_LENGTH = 3;

interface ISourceSelectorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	sourcesIds: TEntityId<number>[];
	sourcesById: IDictionary<ISourceDic>;
	addSource: ActionCreatorWithPayload<number, string>;
	disabled?: boolean;
	changeSources: ActionCreatorWithPayload<number[], string>;
}
const SourceSelector = ({ sourcesIds, sourcesById, addSource, disabled, changeSources }: ISourceSelectorProps) => {
	const [re, setRe] = useState<RegExp | undefined>();

	const sourceOptions = useMemo(
		() =>
			sourcesIds.map((sid) => {
				const { id, title, url, country } = sourcesById[sid] || {
					id: sid,
					title: 'Невідоме джерело',
					url: null,
					country: 'UN',
				};
				return {
					label: title,
					value: id.toString(),
					url,
					country,
				} as ISourceSelectOption;
			}),
		[sourcesIds, sourcesById]
	);

	const onSelectSource = useCallback(
		(source: SingleValue<ISourceSelectOption>, actionMeta: ActionMeta<ISourceSelectOption>) => {
			if (!source) return;
			addSource(parseInt(source.value));
		},
		[addSource]
	);

	const filterOption = useCallback(
		(option: FilterOptionOption<ISourceSelectOption>, rawInput: string) =>
			re ? re.test(`${option.label} ${(option.data as ISourceSelectOption).url}`) : false,
		[re]
	);

	const onInputChange = useCallback((newValue: string, actionMeta: InputActionMeta) => {
		const term = newValue.trim();
		let re: RegExp | undefined = undefined;
		try {
			const reString = term
				.replace(/[іїиы]/gi, '[іїиы]')
				.replace(/[аоя]/gi, '[аоя]')
				.replace(/[еєэ]/gi, '[еєэ]')
				.replace(/[ую]/gi, '[ую]');
			re = term.length < MIN_TERM_LENGTH ? undefined : new RegExp(reString, 'i');
		} catch (error) {}
		setRe(re);
	}, []);

	const selectClassNames = useMemo(
		() => ({
			control: () => 'border-end-0',
			indicatorSeparator: () => 'd-none',
			indicatorsContainer: () => 'd-none',
		}),
		[]
	);

	const noOptionsMessage = useCallback(
		({ inputValue }: { inputValue: string }) =>
			inputValue.trim().length < MIN_TERM_LENGTH ? 'введіть більше символів' : DEFAULTS.noMatch,
		[]
	);

	return (
		<InputGroup className="mb-2">
			<Select
				className="flex-grow-1"
				inputId="pre_sources"
				classNamePrefix="themed-select"
				// inputValue=""
				isSearchable
				classNames={selectClassNames}
				options={sourceOptions}
				value={undefined}
				onChange={onSelectSource}
				placeholder="назва джерела..."
				name="source"
				// styles={
				// 	selectorWithImageStyles('source') as Partial<
				// 		Styles<ISourceSelectOption, false, GroupTypeBase<ISourceSelectOption>>
				// 	>
				// }
				controlShouldRenderValue={false}
				// hideSelectedOptions
				filterOption={filterOption}
				onInputChange={onInputChange}
				components={{ Option: SelectOption }}
				// menuIsOpen
				noOptionsMessage={noOptionsMessage}
				isDisabled={disabled}
			/>
			<DropdownPreSources changeSources={changeSources} disabled={disabled} />
		</InputGroup>
	);
};

export default SourceSelector;

const SelectOption = ({ children, data, ...props }: OptionProps<ISourceSelectOption, false>) => {
	return (
		<components.Option data={data} {...props} className="d-flex align-items-center gap-1 py-1">
			<SourceItemInner sourceId={parseInt(data.value)} country={data.country}>
				{children}
			</SourceItemInner>
		</components.Option>
	);
};
